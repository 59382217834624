import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColIntTime<T extends DataLike = DataLike> = {
  colType: "intTime";
  field: string;
  headerName: string;
  editable?: boolean;
  filterable?: boolean;
  validate?: (row: any, input: any) => string;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  resolve?: (data: T) => string;
};

export function isColIntTime(col: Col): col is ColIntTime {
  return col.colType === "intTime";
}
