import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider as Provider } from "@mui/x-date-pickers";
import { ja } from "date-fns/locale/ja";

export const LocalizationProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      {children}
    </Provider>
  );
};
