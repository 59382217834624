import { ArrowDropDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
} from "@mui/material";
import { Children } from "react";
import s from "./AccordionBase.module.scss";

export type Props = AccordionProps & {
  summary: string | ReactNode;
};

export const AccordionBase: FC<Props> = ({
  children,
  classes,
  summary,
  ...props
}) => {
  return (
    <Box className={s.accordionBase}>
      <Accordion
        {...props}
        classes={{
          root: s.accordion,
          expanded: s.accordionExpanded,
          ...classes,
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          classes={{
            root: s.accordionSummary,
            expanded: s.accordionSummaryExpanded,
            content: s.accordionSummaryContent,
            expandIconWrapper: s.accordionSummaryExpandIconWrapper,
          }}
          expandIcon={<ArrowDropDown className={s.arrorIcon} />}
        >
          {summary}
        </AccordionSummary>

        {Children.map(children, (child, index) => {
          return (
            <AccordionDetails
              key={"AccordionBaseDetail-" + index}
              className={s.accordionDetails}
            >
              {child}
            </AccordionDetails>
          );
        })}
      </Accordion>
    </Box>
  );
};
