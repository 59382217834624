import { ColHidden } from "../Col/ColHidden";

export function ColHiddenDef(colProps: { col: ColHidden }) {
  return {
    field: colProps.col.field,
    headerName: "none",
    type: "string",
    sortable: false,
    editable: true,
    hide: true,
    filterable: false,
    hideable: false,
  };
}
