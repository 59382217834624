import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { Dispatch, SetStateAction } from "react";
import { Cell } from "../Cell/Cell";
import { ColCustom } from "../Col/ColCustom";

export function ColCustomDef(colProp: {
  col: ColCustom;
  apiRef: React.MutableRefObject<GridApiPro>;
  externalRowBooleanState: (rowId: number | string) => {
    [key: string]: boolean;
  };
  updateExternalRowBooleanState: (
    rowId: number | string
  ) => (key: string) => (value: boolean) => void;
  createRow:
    | ((
        input: Record<string, string | number | boolean | Date>
      ) => Promise<boolean>)
    | undefined;
  updateRow:
    | ((
        id: string | number,
        input: Record<string, string | number | boolean | Date>
      ) => Promise<boolean>)
    | undefined;
  deleteRow: ((id: string | number) => Promise<boolean>) | undefined;
  refetch: () => void;
  setCreatingRowID: Dispatch<SetStateAction<string | undefined>>;
  creatingRowID: string | undefined;
  throwServerError: (message: string) => void;
}) {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    type: "actions",
    sortable: false,
    editable: false,
    width: colProp.col.width,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Cell
          params={params}
          apiRef={colProp.apiRef}
          link={colProp.col.link}
          renderEl={colProp.col.renderCell}
          externalState={colProp.externalRowBooleanState(params.id as number)}
          updateExternalState={colProp.updateExternalRowBooleanState(
            params.id as number
          )}
          updateRow={
            colProp.updateRow ??
            ((
              _id: string,
              _input: Record<string, string | number | boolean | Date>
            ) => new Promise<boolean>((_resolve, _reject) => {}))
          }
          deleteRow={
            colProp.deleteRow ??
            ((_id: string) => new Promise<boolean>((_resolve, _reject) => {}))
          }
          refetch={colProp.refetch}
          setCreatingRowID={colProp.setCreatingRowID}
          creatingRowID={colProp.creatingRowID}
          createRow={colProp.createRow}
          throwServerError={colProp.throwServerError}
        />
      );
    },
  };
}
