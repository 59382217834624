import { Box } from "@mui/material";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";

export function CellBoolean(props: {
  params: CustomGridRenderCellParams<boolean>;
  valueFormatter?: (val: boolean | undefined) => string;
}) {
  const { value } = props.params;

  const valueFormatter = props.valueFormatter
    ? props.valueFormatter
    : (val: boolean | undefined) => String(val);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {valueFormatter(value)}
    </Box>
  );
}
