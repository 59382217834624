import { useCallback, useState } from "react";

/**
 * ステータス管理フック
 *
 * @param initialStates
 * @returns
 *
 * @example
 * const { states, setStates } = useStates<{ id: number; name: string; }>({
 *   id: 1,
 *   name: 'ichiro'
 * });
 *
 * setStates({ id: 999 })
 *
 * console.log(states)
 * => { id: 999, name: 'ichiro' }
 *
 * setStates((states) => ({ name: 'jiro' }))
 *
 * console.log(states)
 * => { id: 999, name: 'jiro' }
 *
 * setStates({ id: 0, name: 'saburo' })
 *
 * console.log(states)
 * => { id: 0, name: 'saburo' }
 *
 * 上記は下記と同様
 *
 * const [state, setState] = useState<{ id: number; name: string; }>({
 *   id: 1,
 *   name: 'ichiro'
 * });
 *
 * setState({ ...state, id: 999 })
 * setState((state) => ({ ...state, name: 'jiro' }))
 * setState({ ...state, id: 0, name: 'saburo })
 */
export const useStates = <T extends Obj<unknown>>(initialStates: T) => {
  const [states, set] = useState(initialStates);

  const setStates = useCallback((s: Partial<T> | ((s: T) => Partial<T>)) => {
    return set((states) => ({
      ...states,
      ...(s instanceof Function ? s(states) : s),
    }));
  }, []);

  return { states, setStates };
};
