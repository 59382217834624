import type { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
} from "react";

export function Cell({
  apiRef,
  params,
  link,
  renderEl,
  externalState,
  updateExternalState,
  updateRow,
  deleteRow,
  refetch,
  setCreatingRowID,
  creatingRowID,
  createRow,
  throwServerError,
}: {
  apiRef: MutableRefObject<GridApiPro>;
  params: GridRenderCellParams;
  link?: string[] | undefined;
  renderEl: (row: any) => JSX.Element;
  externalState: { [key: string]: boolean };
  updateExternalState: (key: string) => (value: boolean) => void;
  createRow:
    | ((
        input: Record<string, string | number | boolean | Date>
      ) => Promise<boolean>)
    | undefined;
  updateRow: (
    id: string,
    input: Record<string, string | number | boolean | Date>
  ) => Promise<boolean>;
  deleteRow: (id: string) => Promise<boolean>;
  refetch: () => void;
  setCreatingRowID: Dispatch<SetStateAction<string | undefined>>;
  creatingRowID: string | undefined;
  throwServerError: (message: string) => void;
}) {
  //isNewRow
  const isNewRow: boolean = params.id === creatingRowID;

  let inputRowValue: any = {};
  link?.forEach((field: string) => {
    inputRowValue = {
      ...inputRowValue,
      [field]: params.row[field],
    };
  });

  const startEdit = () => {
    if (!isNewRow) {
      apiRef.current.stopRowEditMode({ id: params.row.id });
      updateExternalState("isEditing")(true);
    }
  };
  const save = () => {
    // const editedData = apiRef.current.getEditRowsModel()[params.row.id] as {
    //   [key: string]: { value: any };
    // };
    // let inputValue = {};
    // Object.keys(editedData).forEach((field: string) => {
    //   inputValue = {
    //     ...inputValue,
    //     [field]: editedData[field].value,
    //   };
    // });
    // if (!isNewRow) {
    //   // update db
    //   // call update mutation
    //   updateRow(apiRef.current.getRow(params.row.id).id, inputValue)
    //     .then((_) => {
    //       const result = apiRef.current.stopRowEditMode({ id: params.row.id });
    //       if (result) {
    //         apiRef.current.setRowMode(params.row.id, "view");
    //         updateExternalState("isEditing")(false);
    //         setTimeout(refetch, 1000);
    //       }
    //     })
    //     .catch((error) => {
    //       throwServerError(error.message);
    //     });
    // } else {
    //   // call create mutation
    //   if (createRow && creatingRowID) {
    //     apiRef.current.stopRowEditMode({ id: creatingRowID });
    //     createRow(inputValue)
    //       .then((created) => {
    //         if (created) {
    //           setCreatingRowID(() => undefined);
    //           setTimeout(refetch, 1000);
    //         }
    //       })
    //       .catch((error) => {
    //         throwServerError(error.message);
    //         setCreatingRowID(() => undefined);
    //       });
    //   }
    // }
  };
  const cancelEdit = () => {
    if (!isNewRow) {
      apiRef.current.stopRowEditMode({ id: params.row.id });
      updateExternalState("isEditing")(false);
    } else {
      //Delete new row in client only
      setCreatingRowID(() => undefined);
    }
  };
  const onClickDeleteRow = useCallback(() => {
    if (!isNewRow) {
      //delete from DB
      deleteRow(params.row.id)
        .then((result) => {
          if (result) {
            setTimeout(refetch, 1000);
          } else {
            //TODO error handling
          }
        })
        .catch((e) => {
          console.error(e);
          throwServerError(e.message);
        });
    } else {
      //This block would not be executed
      console.error("this block would not be executed");
      //Delete new row in client only
      setCreatingRowID(() => undefined);
    }
  }, [refetch, params.id, deleteRow, params.row, isNewRow]);

  inputRowValue = {
    ...inputRowValue,
    startEdit,
    save,
    cancelEdit,
    delete: onClickDeleteRow,
    isEditing: externalState?.isEditing ?? false,
    isError: externalState?.isError ?? false,
    isLoading: externalState?.isLoading ?? false,
    throwServerError,
  };

  const RenderEl: FC<{ row: any }> = renderEl;

  return <>{RenderEl(inputRowValue)}</>;
}
