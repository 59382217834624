import type { QueryVariables } from "./filterModel";

export const mergeQueryVariables = (
  existingVariables: QueryVariables,
  newQuery: QueryVariables
): QueryVariables => {
  const { query: existingQuery, ...otherVariables } = existingVariables;

  // Remove 'and' and 'or' properties from existingQuery for overwriting
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { and, or, ...cleanedExistingQuery } = existingQuery || {};

  return {
    ...otherVariables,
    query: {
      ...cleanedExistingQuery,
      ...newQuery,
    },
  };
};
