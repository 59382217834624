import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColEnum<T extends DataLike = DataLike> = {
  colType: "enum";
  field: string;
  headerName: string;
  editable?: boolean;
  dic: { name: string; value: string }[];
  filterable?: boolean;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  validate?: (row: any, input: any) => string;
  multiple?: boolean;
  resolve?: (data: T) => string;
  valueFormatter?: ({ value }: { value: string }) => string;
};

export function isColEnum(col: Col): col is ColEnum {
  return col.colType === "enum";
}
