import { Box, Popper, TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import { useCallback, useMemo, useRef } from "react";

export function EditCellString(props: {
  params: CustomGridRenderEditCellParams<string>;
  externalBooleanState: {
    [key: string]: boolean;
  };
  updateExternalBooleanState: (key: string) => (value: boolean) => void;
  updateExternalStringState: (key: string) => (value: string) => void;
  validate?: (row: any, input: any) => string;
  validateAsync?: (row: any, input: any) => Promise<string>;
  externalStringState: {
    [key: string]: string;
  };
  width?: number;
}) {
  const { id, value, field } = props.params;
  const apiRef = useGridApiContext();

  const error = useMemo(
    () => props.externalStringState?.["errorMessage_" + field] ?? "",
    [props, field]
  );

  //pop error
  const ref = useRef<HTMLElement>(null);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
        ref={ref}
      >
        <TextField
          hiddenLabel
          defaultValue={value}
          variant="standard"
          size="small"
          onChange={(event) => {
            if ("value" in event.target) {
              apiRef.current.setEditCellValue({
                id,
                field,
                value: event.target.value,
              });
            }
          }}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            "& .MuiInput-input": {
              padding: 0,
            },
          }}
        />
      </Box>
      <Popper open={error !== ""} anchorEl={ref.current}>
        <Box
          sx={{
            fontSize: "11px",
            color: "red",
            fontWeight: "bold",
            backgroundColor: "#ffffff",
            width: props.width,
            maxWidth: "90%",
            borderRadius: 1,
            boxShadow: 2,
            margin: "4px auto",
            padding: "2px 4px",
          }}
        >
          {error}
        </Box>
      </Popper>
    </>
  );
}
