import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  GridCellParams,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import clsx from "clsx";
import React from "react";
import { CellString } from "../Cell/CellString";
import { CellStringArray } from "../Cell/CellStringArray";
import { ColEnum } from "../Col/ColEnum";
import { EditCellEnum } from "../EditCell/EditCellEnum";
import { EditCellEnumMultiple } from "../EditCell/EditCellEnumMultiple";

export function ColEnumDef(colProp: {
  col: ColEnum;
  externalRowBooleanState: (rowId: number | string) => {
    [key: string]: boolean;
  };
  updateExternalRowBooleanState: (
    rowId: number | string
  ) => (key: string) => (value: boolean) => void;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
}) {
  const EnumInputValue = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const handleFilterChange = (event: SelectChangeEvent<string>) => {
      if ("value" in event.target) {
        applyValue({ ...item, value: event.target.value });
      }
    };

    return (
      <FormControl fullWidth sx={{ height: "63px" }}>
        <InputLabel
          id="enum-filter-select-label"
          sx={{ top: "8px", left: "-15px" }}
        >
          {colProp.col.headerName}
        </InputLabel>
        <Select
          sx={{ paddingLeft: "0px" }}
          labelId="enum-filter-select-label"
          id="demo-simple-select"
          label={colProp.col.headerName}
          defaultValue={item.value ?? "undefined"}
          onChange={handleFilterChange}
          variant="standard"
        >
          {colProp.col.dic.map((e) => (
            <MenuItem value={e.value} key={e.value}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const equalOperator: GridFilterOperator = {
    label: "に等しい",
    value: "equal",
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      //not need to implement because of server side filtering
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value === filterItem.value;
      };
    },
    InputComponent: EnumInputValue,
    //ここに何を入れるか問題
    // InputComponentProps: undefined,
  };
  const notEqualOperator: GridFilterOperator = {
    label: "に等しくない",
    value: "notEqual",
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value !== filterItem.value;
      };
    },
    InputComponent: EnumInputValue,
    // InputComponentProps: undefined,
  };
  return colProp.col.multiple
    ? {
        field: colProp.col.field,
        headerName: colProp.col.headerName,
        filterable: colProp.col.filterable,
        editable: colProp.col.editable,
        sortable: colProp.col.sortable,
        width: colProp.col.width,
        type: "string",
        valueFormatter: colProp.col.valueFormatter,
        renderCell: (params: GridRenderCellParams<string[]>) => (
          <CellStringArray
            params={params}
            valueFormatter={(values: string[]) =>
              values.map((value) =>
                colProp.col.dic
                  ? colProp.col.dic.find(
                      (u: ColEnum["dic"][number]) => u.value === value
                    )?.name ?? ""
                  : value
              )
            }
          />
        ),
        renderEditCell: (params: GridRenderEditCellParams<string[]>) => (
          <EditCellEnumMultiple
            params={params}
            list={colProp.col.dic}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            externalStringState={colProp.externalRowStringState(params.id)}
          />
        ),
        cellClassName: () => {
          return colProp.col.editable ? clsx("editable") : clsx("disabled");
        },
        filterOperators: [equalOperator, notEqualOperator],
      }
    : {
        field: colProp.col.field,
        headerName: colProp.col.headerName,
        type: "string",
        filterable: colProp.col.filterable,
        editable: colProp.col.editable,
        sortable: colProp.col.sortable,
        width: colProp.col.width,
        valueFormatter: colProp.col.valueFormatter,
        renderCell: (params: CustomGridRenderCellParams<string>) => (
          <CellString
            params={params}
            valueFormatter={(value: string) =>
              colProp.col.dic
                ? colProp.col.dic.find(
                    (u: { value: string; name: string }) => u.value === value
                  )?.name ?? ""
                : value
            }
          />
        ),
        renderEditCell: (params: CustomGridRenderEditCellParams<string>) => (
          <EditCellEnum
            params={params}
            list={colProp.col.dic}
            width={colProp.col.width}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            externalStringState={colProp.externalRowStringState(params.id)}
          />
        ),
        cellClassName: () => {
          return colProp.col.editable ? clsx("editable") : clsx("disabled");
        },
        filterOperators: [equalOperator, notEqualOperator],
      };
}
