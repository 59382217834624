import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColHidden<T extends DataLike = DataLike> = {
  colType: "hidden";
  field: string;
  type:
    | "string"
    | "number"
    | "date"
    | "dateTime"
    | "boolean"
    | "singleSelect"
    | "actions";
  isKey?: boolean;
  resolve?: (data: T) => string;
};

export function isColHidden(col: Col): col is ColHidden {
  return col.colType === "hidden";
}
