import { Box, FormControl, MenuItem, Popper, Select } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useRef, useState } from "react";
import { ColEnum } from "../Col/ColEnum";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";

export function EditCellEnum(props: {
  params: CustomGridRenderEditCellParams<string>;
  width?: number | undefined;
  list: ColEnum["dic"];
  externalStringState: {
    [key: string]: string;
  };
  externalBooleanState: {
    [key: string]: boolean;
  };
  updateExternalBooleanState: (key: string) => (value: boolean) => void;
}) {
  const { id, value, field } = props.params;
  const apiRef = useGridApiContext();
  const error = useMemo(
    () => props.externalStringState?.["errorMessage_" + field] ?? "",
    [props, field]
  );
  const [val, setVal] = useState<"" | string>(value ?? "");

  //pop error
  const ref = useRef<HTMLElement | null>(null);

  //handle error
  //update external state when some error occurred
  useEffect(() => {
    if (error !== "") {
      props.updateExternalBooleanState("isError_" + field)(true);
    } else {
      props.updateExternalBooleanState("isError_" + field)(false);
    }
  }, [error]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
        ref={ref}
      >
        <FormControl fullWidth sx={{ minWidth: 100 }}>
          <Select<string>
            value={val}
            disableUnderline
            variant="standard"
            onChange={(event) => {
              if ("value" in event.target) {
                setVal(event.target.value);
                apiRef.current.setEditCellValue({
                  id,
                  field,
                  value: event.target.value,
                });
              }
            }}
            sx={{
              "& .MuiInput-input": {
                padding: 0,
              },
            }}
          >
            {props.list.map((u, i) => (
              <MenuItem value={u.value} key={i}>
                {u.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Popper open={error !== ""} anchorEl={ref.current}>
        <Box
          sx={{
            fontSize: "11px",
            color: "red",
            fontWeight: "bold",
            backgroundColor: "#ffffff",
            width: props.width || null,
            maxWidth: "90%",
            borderRadius: 1,
            boxShadow: 2,
            margin: "4px auto",
            padding: "2px 4px",
          }}
        >
          {error}
        </Box>
      </Popper>
    </>
  );
}
