import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColTime<T extends DataLike = DataLike> = {
  colType: "time";
  field: string;
  headerName: string;
  validate?: (row: any, input: any) => string;
  editable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  resolve?: (data: T) => string;
};

export function isColTime(col: Col): col is ColTime {
  return col.colType === "time";
}
// Definition
// # format of state of this cell
//    -  "yyyy-mm-ddThh:mm:ssZ" (String; same with GraphQL datetime types)
// # format of displayed vlaue
//    -  "hh:mm"
// # edit cell type
//    - string
//    - validation format is hhmm("0000 - 4759")
// # render cell type
//    - string
//    - hh:mm
