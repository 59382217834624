import { Box, Popper, TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function EditCellNumber(props: {
  params: CustomGridRenderEditCellParams<number>;
  externalStringState: {
    [key: string]: string;
  };
  updateExternalBooleanState: (key: string) => (value: boolean) => void;
  width?: number;
}) {
  const { id, value, field } = props.params;
  const apiRef = useGridApiContext();
  const [val, setVal] = useState<number>(value ?? 0);
  const error = useMemo(
    () => props.externalStringState?.["errorMessage_" + field] ?? "",
    [props]
  );

  //update external state when some error occurred
  useEffect(() => {
    if (error !== "") {
      props.updateExternalBooleanState("isError_" + field)(true);
    } else {
      props.updateExternalBooleanState("isError_" + field)(false);
    }
  }, [error, field]);

  const reg = RegExp("^([0-9]*)$");
  const ref = useRef<HTMLElement>(null);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} ref={ref}>
      <TextField
        hiddenLabel
        value={val}
        variant="standard"
        size="small"
        onChange={(event) => {
          if (
            "value" in event.target &&
            typeof event.target.value === "string" &&
            reg.test(event.target.value)
          ) {
            apiRef.current.setEditCellValue({
              id,
              field,
              value: event.target.value,
            });
            setVal(Number(event.target.value));
          }
        }}
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          "& .MuiInput-input": {
            padding: 0,
          },
          backgroundColor: "#f5f5f5",
        }}
      />
      <Popper open={error !== ""} anchorEl={ref.current}>
        <Box
          sx={{
            backgroundColor: "white !important",
            color: "black",
            width: props.width,
            marginTop: 0,
            border: "1px solid #e5e5e5",
            borderRadius: 1,
          }}
        >
          {error}
        </Box>
      </Popper>
    </Box>
  );
}
