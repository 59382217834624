import { Box, FormControl, MenuItem, Popper, Select } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useEffect, useMemo, useRef, useState } from "react";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";

export function EditCellBoolean(props: {
  params: CustomGridRenderEditCellParams<boolean>;
  valueFormatter: (val: boolean) => string;
  width?: number | undefined;
  externalStringState: {
    [key: string]: string;
  };
  updateExternalBooleanState: (key: string) => (value: boolean) => void;
}) {
  const { id, value, field } = props.params;
  const apiRef = useGridApiContext();
  const [val, setVal] = useState<boolean>(value ?? false);
  const error = useMemo(
    () => props.externalStringState?.["errorMessage_" + field] ?? "",
    [props, field]
  );

  //pop error
  const ref = useRef<HTMLElement>(null);

  //update external state when some error occurred
  useEffect(() => {
    if (value === undefined) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: false,
      });
    }
    if (error !== "") {
      props.updateExternalBooleanState("isError_" + props.params.field)(true);
    } else {
      props.updateExternalBooleanState("isError_" + props.params.field)(false);
    }
  }, [error]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
        ref={ref}
      >
        <FormControl fullWidth sx={{ minWidth: 100 }}>
          <Select
            value={val ? "true" : "false"}
            disableUnderline
            variant="standard"
            onChange={(event) => {
              if ("value" in event.target) {
                setVal(event.target.value === "true");
                apiRef.current.setEditCellValue({
                  id,
                  field,
                  value: event.target.value === "true",
                });
              }
            }}
            sx={{
              "& .MuiInput-input": {
                padding: 0,
              },
            }}
          >
            <MenuItem value="true">{props.valueFormatter(true)}</MenuItem>
            <MenuItem value="false">{props.valueFormatter(false)}</MenuItem>
          </Select>
        </FormControl>
        <Popper open={error !== ""} anchorEl={ref.current}>
          <Box
            sx={{
              fontSize: "11px",
              color: "red",
              fontWeight: "bold",
              backgroundColor: "#ffffff",
              width: props.width,
              maxWidth: "90%",
              borderRadius: 1,
              boxShadow: 2,
              margin: "4px auto",
              padding: "2px 4px",
            }}
          >
            {error}
          </Box>
        </Popper>
      </Box>
    </>
  );
}
