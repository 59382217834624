import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import clsx from "clsx";
import { ColIntTime } from "../Col/ColIntTime";
import { CellIntTime } from "../Cell/CellIntTime";

export function ColIntTimeDef(colProp: {
  col: ColIntTime;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
}) {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    type: "number",
    filterable: colProp.col.filterable,
    editable: colProp.col.editable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    renderCell: (params: GridRenderCellParams) => (
      <CellIntTime params={params} />
    ),
    //TODO: edit cell
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
  };
}
