// Code generated by graphql-codegen. DO NOT EDIT.
import * as Types from "../types/generated";

import { TailorGqlDate, TailorGqlTime } from "@tailor-platform/client";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type OfficeItemFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  code?: string | null;
  parent?: {
    __typename?: "Group";
    id: string;
    name: string;
    code?: string | null;
  } | null;
};

export type FetchOfficeQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FetchOfficeQuery = {
  __typename?: "Query";
  office?: {
    __typename?: "Group";
    id: string;
    name: string;
    code?: string | null;
    parent?: {
      __typename?: "Group";
      id: string;
      name: string;
      code?: string | null;
    } | null;
  } | null;
};

export type SearchOfficesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.GroupQueryInput>;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.GroupOrderInput>>
    | Types.InputMaybe<Types.GroupOrderInput>
  >;
}>;

export type SearchOfficesQuery = {
  __typename?: "Query";
  offices?: {
    __typename?: "GroupCollection";
    total: number;
    collection: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      code?: string | null;
      parent?: {
        __typename?: "Group";
        id: string;
        name: string;
        code?: string | null;
      } | null;
    }>;
  } | null;
};

export type SalesOfficeItemFragment = {
  __typename?: "SalesOffice";
  id: string;
  groupID: string;
  code: string;
  name: string;
  managers: Array<{
    __typename?: "SalesOfficeManagers";
    employeeID: string;
    startAt?: any | null;
    endAt?: any | null;
    roleType: Types.SalesOfficeManagersRoleType;
    userGroupID: string;
    employee?: {
      __typename?: "Employee";
      id: string;
      name: string;
      employeeCode: string;
      employeeCategoryID: string;
      defaultContractCode?: string | null;
      defaultOfficeCode: string;
      defaultScheduledBreakTime?: TailorGqlTime | null;
      defaultScheduledStartedAt?: TailorGqlTime | null;
      defaultScheduledEndedAt?: TailorGqlTime | null;
      joinedAt: TailorGqlDate;
      userID: string;
      employeeCategory?: {
        __typename?: "EmployeeCategory";
        name: string;
        code: number;
      } | null;
      paymentCategory?: {
        __typename?: "PaymentCategory";
        id: string;
        name: string;
        code: number;
      } | null;
      user?: {
        __typename?: "User";
        userRoles: Array<{
          __typename?: "UserRole";
          role?: { __typename?: "Role"; name: string } | null;
        }>;
        userGroups: Array<{
          __typename?: "UserGroup";
          group?: { __typename?: "Group"; name: string } | null;
        }>;
      } | null;
    } | null;
  }>;
};

export type SearchSalesOfficesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.SalesOfficeQueryInput>;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.SalesOfficeOrderInput>>
    | Types.InputMaybe<Types.SalesOfficeOrderInput>
  >;
}>;

export type SearchSalesOfficesQuery = {
  __typename?: "Query";
  salesOffices?: {
    __typename?: "SalesOfficeResult";
    total: number;
    collection: Array<{
      __typename?: "SalesOffice";
      id: string;
      groupID: string;
      code: string;
      name: string;
      managers: Array<{
        __typename?: "SalesOfficeManagers";
        employeeID: string;
        startAt?: any | null;
        endAt?: any | null;
        roleType: Types.SalesOfficeManagersRoleType;
        userGroupID: string;
        employee?: {
          __typename?: "Employee";
          id: string;
          name: string;
          employeeCode: string;
          employeeCategoryID: string;
          defaultContractCode?: string | null;
          defaultOfficeCode: string;
          defaultScheduledBreakTime?: TailorGqlTime | null;
          defaultScheduledStartedAt?: TailorGqlTime | null;
          defaultScheduledEndedAt?: TailorGqlTime | null;
          joinedAt: TailorGqlDate;
          userID: string;
          employeeCategory?: {
            __typename?: "EmployeeCategory";
            name: string;
            code: number;
          } | null;
          paymentCategory?: {
            __typename?: "PaymentCategory";
            id: string;
            name: string;
            code: number;
          } | null;
          user?: {
            __typename?: "User";
            userRoles: Array<{
              __typename?: "UserRole";
              role?: { __typename?: "Role"; name: string } | null;
            }>;
            userGroups: Array<{
              __typename?: "UserGroup";
              group?: { __typename?: "Group"; name: string } | null;
            }>;
          } | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type SearchSalesOfficesOnMasterQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.SalesOfficeQueryInput>;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.SalesOfficeOrderInput>>
    | Types.InputMaybe<Types.SalesOfficeOrderInput>
  >;
}>;

export type SearchSalesOfficesOnMasterQuery = {
  __typename?: "Query";
  salesOffices?: {
    __typename?: "SalesOfficeResult";
    total: number;
    collection: Array<{
      __typename?: "SalesOffice";
      id: string;
      groupID: string;
      code: string;
      name: string;
    }>;
  } | null;
};

export type FetchSalesOfficeQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FetchSalesOfficeQuery = {
  __typename?: "Query";
  salesOffice?: {
    __typename?: "SalesOffice";
    id: string;
    groupID: string;
    code: string;
    name: string;
    managers: Array<{
      __typename?: "SalesOfficeManagers";
      employeeID: string;
      startAt?: any | null;
      endAt?: any | null;
      roleType: Types.SalesOfficeManagersRoleType;
      userGroupID: string;
      employee?: {
        __typename?: "Employee";
        id: string;
        name: string;
        employeeCode: string;
        employeeCategoryID: string;
        defaultContractCode?: string | null;
        defaultOfficeCode: string;
        defaultScheduledBreakTime?: TailorGqlTime | null;
        defaultScheduledStartedAt?: TailorGqlTime | null;
        defaultScheduledEndedAt?: TailorGqlTime | null;
        joinedAt: TailorGqlDate;
        userID: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
          code: number;
        } | null;
        paymentCategory?: {
          __typename?: "PaymentCategory";
          id: string;
          name: string;
          code: number;
        } | null;
        user?: {
          __typename?: "User";
          userRoles: Array<{
            __typename?: "UserRole";
            role?: { __typename?: "Role"; name: string } | null;
          }>;
          userGroups: Array<{
            __typename?: "UserGroup";
            group?: { __typename?: "Group"; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type UpdateSalesOfficeMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  input: Types.SalesOfficeUpdateInput;
}>;

export type UpdateSalesOfficeMutation = {
  __typename?: "Mutation";
  updateSalesOffice?: {
    __typename?: "SalesOffice";
    id: string;
    groupID: string;
    code: string;
    name: string;
    managers: Array<{
      __typename?: "SalesOfficeManagers";
      employeeID: string;
      startAt?: any | null;
      endAt?: any | null;
      roleType: Types.SalesOfficeManagersRoleType;
      userGroupID: string;
      employee?: {
        __typename?: "Employee";
        id: string;
        name: string;
        employeeCode: string;
        employeeCategoryID: string;
        defaultContractCode?: string | null;
        defaultOfficeCode: string;
        defaultScheduledBreakTime?: TailorGqlTime | null;
        defaultScheduledStartedAt?: TailorGqlTime | null;
        defaultScheduledEndedAt?: TailorGqlTime | null;
        joinedAt: TailorGqlDate;
        userID: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
          code: number;
        } | null;
        paymentCategory?: {
          __typename?: "PaymentCategory";
          id: string;
          name: string;
          code: number;
        } | null;
        user?: {
          __typename?: "User";
          userRoles: Array<{
            __typename?: "UserRole";
            role?: { __typename?: "Role"; name: string } | null;
          }>;
          userGroups: Array<{
            __typename?: "UserGroup";
            group?: { __typename?: "Group"; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AddUserToSalesOfficeMutationVariables = Types.Exact<{
  input: Types.AddUserToSalesOfficeInput;
}>;

export type AddUserToSalesOfficeMutation = {
  __typename?: "Mutation";
  addUserToSalesOffice?: string | null;
};

export type DelUserFromSalesOfficeMutationVariables = Types.Exact<{
  input: Types.DeleteUserFromSalesOfficeInput;
}>;

export type DelUserFromSalesOfficeMutation = {
  __typename?: "Mutation";
  deleteUserFromSalesOffice?: boolean | null;
};

export type RoleItemFragment = {
  __typename?: "Role";
  id: string;
  name: string;
  roleClass: { __typename?: "RoleClass"; id: string; name?: string | null };
};

export type ContractItemFragment = {
  __typename?: "Contract";
  id: string;
  name: string;
  code: string;
  workplaceName: string;
  salesOffice?: {
    __typename?: "SalesOffice";
    id: string;
    groupID: string;
    code: string;
    name: string;
    managers: Array<{
      __typename?: "SalesOfficeManagers";
      employeeID: string;
      startAt?: any | null;
      endAt?: any | null;
      roleType: Types.SalesOfficeManagersRoleType;
      userGroupID: string;
      employee?: {
        __typename?: "Employee";
        id: string;
        name: string;
        employeeCode: string;
        employeeCategoryID: string;
        defaultContractCode?: string | null;
        defaultOfficeCode: string;
        defaultScheduledBreakTime?: TailorGqlTime | null;
        defaultScheduledStartedAt?: TailorGqlTime | null;
        defaultScheduledEndedAt?: TailorGqlTime | null;
        joinedAt: TailorGqlDate;
        userID: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
          code: number;
        } | null;
        paymentCategory?: {
          __typename?: "PaymentCategory";
          id: string;
          name: string;
          code: number;
        } | null;
        user?: {
          __typename?: "User";
          userRoles: Array<{
            __typename?: "UserRole";
            role?: { __typename?: "Role"; name: string } | null;
          }>;
          userGroups: Array<{
            __typename?: "UserGroup";
            group?: { __typename?: "Group"; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ManagerItemFragment = {
  __typename?: "ContractManagers";
  employeeID: string;
  startAt?: any | null;
  endAt?: any | null;
  roleType: Types.ContractManagersRoleType;
  userRoleID: string;
  employee?: {
    __typename?: "Employee";
    id: string;
    name: string;
    employeeCode: string;
    employeeCategoryID: string;
    defaultContractCode?: string | null;
    defaultOfficeCode: string;
    defaultScheduledBreakTime?: TailorGqlTime | null;
    defaultScheduledStartedAt?: TailorGqlTime | null;
    defaultScheduledEndedAt?: TailorGqlTime | null;
    joinedAt: TailorGqlDate;
    userID: string;
    employeeCategory?: {
      __typename?: "EmployeeCategory";
      name: string;
      code: number;
    } | null;
    paymentCategory?: {
      __typename?: "PaymentCategory";
      id: string;
      name: string;
      code: number;
    } | null;
    user?: {
      __typename?: "User";
      userRoles: Array<{
        __typename?: "UserRole";
        role?: { __typename?: "Role"; name: string } | null;
      }>;
      userGroups: Array<{
        __typename?: "UserGroup";
        group?: { __typename?: "Group"; name: string } | null;
      }>;
    } | null;
  } | null;
};

export type ContractDetailItemFragment = {
  __typename?: "Contract";
  id: string;
  name: string;
  code: string;
  workplaceName: string;
  managers: Array<{
    __typename?: "ContractManagers";
    employeeID: string;
    startAt?: any | null;
    endAt?: any | null;
    roleType: Types.ContractManagersRoleType;
    userRoleID: string;
    employee?: {
      __typename?: "Employee";
      id: string;
      name: string;
      employeeCode: string;
      employeeCategoryID: string;
      defaultContractCode?: string | null;
      defaultOfficeCode: string;
      defaultScheduledBreakTime?: TailorGqlTime | null;
      defaultScheduledStartedAt?: TailorGqlTime | null;
      defaultScheduledEndedAt?: TailorGqlTime | null;
      joinedAt: TailorGqlDate;
      userID: string;
      employeeCategory?: {
        __typename?: "EmployeeCategory";
        name: string;
        code: number;
      } | null;
      paymentCategory?: {
        __typename?: "PaymentCategory";
        id: string;
        name: string;
        code: number;
      } | null;
      user?: {
        __typename?: "User";
        userRoles: Array<{
          __typename?: "UserRole";
          role?: { __typename?: "Role"; name: string } | null;
        }>;
        userGroups: Array<{
          __typename?: "UserGroup";
          group?: { __typename?: "Group"; name: string } | null;
        }>;
      } | null;
    } | null;
  }>;
};

export type SearchContractsQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.ContractQueryInput>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.ContractOrderInput>>
    | Types.InputMaybe<Types.ContractOrderInput>
  >;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type SearchContractsQuery = {
  __typename?: "Query";
  contracts?: {
    __typename?: "ContractResult";
    total: number;
    collection: Array<{
      __typename?: "Contract";
      id: string;
      name: string;
      workplaceName: string;
      code: string;
    }>;
  } | null;
};

export type SearchContractsOnMasterQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.ContractQueryInput>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.ContractOrderInput>>
    | Types.InputMaybe<Types.ContractOrderInput>
  >;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type SearchContractsOnMasterQuery = {
  __typename?: "Query";
  contracts?: {
    __typename?: "ContractResult";
    total: number;
    collection: Array<{
      __typename?: "Contract";
      id: string;
      name: string;
      code: string;
      workplaceName: string;
      salesOffice?: {
        __typename?: "SalesOffice";
        id: string;
        name: string;
        code: string;
      } | null;
    }>;
  } | null;
};

export type FetchContractQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FetchContractQuery = {
  __typename?: "Query";
  contract?: {
    __typename?: "Contract";
    id: string;
    name: string;
    code: string;
    workplaceName: string;
    managers: Array<{
      __typename?: "ContractManagers";
      employeeID: string;
      startAt?: any | null;
      endAt?: any | null;
      roleType: Types.ContractManagersRoleType;
      userRoleID: string;
      employee?: {
        __typename?: "Employee";
        id: string;
        name: string;
        employeeCode: string;
        employeeCategoryID: string;
        defaultContractCode?: string | null;
        defaultOfficeCode: string;
        defaultScheduledBreakTime?: TailorGqlTime | null;
        defaultScheduledStartedAt?: TailorGqlTime | null;
        defaultScheduledEndedAt?: TailorGqlTime | null;
        joinedAt: TailorGqlDate;
        userID: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
          code: number;
        } | null;
        paymentCategory?: {
          __typename?: "PaymentCategory";
          id: string;
          name: string;
          code: number;
        } | null;
        user?: {
          __typename?: "User";
          userRoles: Array<{
            __typename?: "UserRole";
            role?: { __typename?: "Role"; name: string } | null;
          }>;
          userGroups: Array<{
            __typename?: "UserGroup";
            group?: { __typename?: "Group"; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type UpdateContractMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  input: Types.ContractUpdateInput;
}>;

export type UpdateContractMutation = {
  __typename?: "Mutation";
  updateContract?: {
    __typename?: "Contract";
    id: string;
    name: string;
    code: string;
    workplaceName: string;
    managers: Array<{
      __typename?: "ContractManagers";
      employeeID: string;
      startAt?: any | null;
      endAt?: any | null;
      roleType: Types.ContractManagersRoleType;
      userRoleID: string;
      employee?: {
        __typename?: "Employee";
        id: string;
        name: string;
        employeeCode: string;
        employeeCategoryID: string;
        defaultContractCode?: string | null;
        defaultOfficeCode: string;
        defaultScheduledBreakTime?: TailorGqlTime | null;
        defaultScheduledStartedAt?: TailorGqlTime | null;
        defaultScheduledEndedAt?: TailorGqlTime | null;
        joinedAt: TailorGqlDate;
        userID: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
          code: number;
        } | null;
        paymentCategory?: {
          __typename?: "PaymentCategory";
          id: string;
          name: string;
          code: number;
        } | null;
        user?: {
          __typename?: "User";
          userRoles: Array<{
            __typename?: "UserRole";
            role?: { __typename?: "Role"; name: string } | null;
          }>;
          userGroups: Array<{
            __typename?: "UserGroup";
            group?: { __typename?: "Group"; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AddUserToContractMutationVariables = Types.Exact<{
  input: Types.AddUserToContractInput;
}>;

export type AddUserToContractMutation = {
  __typename?: "Mutation";
  addUserToContract?: string | null;
};

export type DelUserFromContractMutationVariables = Types.Exact<{
  input: Types.DeleteUserFromContractInput;
}>;

export type DelUserFromContractMutation = {
  __typename?: "Mutation";
  deleteUserFromContract?: boolean | null;
};

export type RecordTypeItemFragment = {
  __typename?: "RecordType";
  id: string;
  name: string;
  isHoliday?: boolean | null;
  isCommentable: boolean;
  needTimeInput?: boolean | null;
  needLateTime?: boolean | null;
  needTransportCosts?: boolean | null;
  useUserInputPrescribedTime?: boolean | null;
  useDefaultPrescribedTime?: boolean | null;
  displayPriority?: number | null;
};

export type FetchRecordTypesQueryVariables = Types.Exact<{
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.RecordTypeOrderInput>>
    | Types.InputMaybe<Types.RecordTypeOrderInput>
  >;
}>;

export type FetchRecordTypesQuery = {
  __typename?: "Query";
  recordTypes?: {
    __typename?: "RecordTypeResult";
    collection: Array<{
      __typename?: "RecordType";
      id: string;
      name: string;
      isHoliday?: boolean | null;
      isCommentable: boolean;
      needTimeInput?: boolean | null;
      needLateTime?: boolean | null;
      needTransportCosts?: boolean | null;
      useUserInputPrescribedTime?: boolean | null;
      useDefaultPrescribedTime?: boolean | null;
      displayPriority?: number | null;
    }>;
  } | null;
};

export type SearchApplicationDatesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.WorkRecordQueryInput>;
}>;

export type SearchApplicationDatesQuery = {
  __typename?: "Query";
  workRecords?: {
    __typename?: "WorkRecordResult";
    collection: Array<{
      __typename?: "WorkRecord";
      applicationDate: TailorGqlDate;
    }>;
  } | null;
};

export type EmployeeItemFragment = {
  __typename?: "Employee";
  id: string;
  name: string;
  employeeCode: string;
  employeeCategoryID: string;
  defaultContractCode?: string | null;
  defaultOfficeCode: string;
  defaultScheduledBreakTime?: TailorGqlTime | null;
  defaultScheduledStartedAt?: TailorGqlTime | null;
  defaultScheduledEndedAt?: TailorGqlTime | null;
  joinedAt: TailorGqlDate;
  userID: string;
  employeeCategory?: {
    __typename?: "EmployeeCategory";
    name: string;
    code: number;
  } | null;
  paymentCategory?: {
    __typename?: "PaymentCategory";
    id: string;
    name: string;
    code: number;
  } | null;
  user?: {
    __typename?: "User";
    userRoles: Array<{
      __typename?: "UserRole";
      role?: { __typename?: "Role"; name: string } | null;
    }>;
    userGroups: Array<{
      __typename?: "UserGroup";
      group?: { __typename?: "Group"; name: string } | null;
    }>;
  } | null;
};

export type WorkRecordItemFragment = {
  __typename?: "WorkRecord";
  id: string;
  applicantCategoryCode: string;
  applicantCode?: string | null;
  applicantID: string;
  applicantName?: string | null;
  applicationDate: TailorGqlDate;
  employeeCategory?: string | null;
  contractID: string;
  contractCode: string;
  contractName?: string | null;
  contractWorkplaceName?: string | null;
  recordTypeID: string;
  startedAt?: TailorGqlTime | null;
  endedAt?: TailorGqlTime | null;
  breakTime?: TailorGqlTime | null;
  lateTime?: TailorGqlTime | null;
  lateWorkingTime?: TailorGqlTime | null;
  legalOverworkingTime?: TailorGqlTime | null;
  legalWorkingTime?: TailorGqlTime | null;
  scheduledStartedAt?: TailorGqlTime | null;
  scheduledEndedAt?: TailorGqlTime | null;
  scheduledBreakTime?: TailorGqlTime | null;
  workingTime?: TailorGqlTime | null;
  holidayWorkingTime?: TailorGqlTime | null;
  inPrescribedTime?: TailorGqlTime | null;
  statutoryOverworkingTime?: TailorGqlTime | null;
  bindingTime?: TailorGqlTime | null;
  prescribedTime?: TailorGqlTime | null;
  workComment?: string | null;
  transportCostType?: Types.WorkRecordTransportCostType | null;
  stateID: string;
  currentState: string;
  applicant?: {
    __typename?: "Employee";
    name: string;
    employeeCode: string;
    employeeCategory?: { __typename?: "EmployeeCategory"; name: string } | null;
  } | null;
  contract?: { __typename?: "Contract"; name: string; code: string } | null;
  recordType?: {
    __typename?: "RecordType";
    id: string;
    name: string;
    isHoliday?: boolean | null;
    isCommentable: boolean;
    needTimeInput?: boolean | null;
    needLateTime?: boolean | null;
    needTransportCosts?: boolean | null;
    useUserInputPrescribedTime?: boolean | null;
    useDefaultPrescribedTime?: boolean | null;
    displayPriority?: number | null;
  } | null;
  state?: {
    __typename?: "State";
    id: string;
    name?: string | null;
    currentState?: string | null;
    histories?: Array<{
      __typename?: "StateHistory";
      id: string;
      state: string;
      action: string;
      approver: string;
      createdAt: any;
    }> | null;
  } | null;
};

export type DeletedWorkRecordItemFragment = {
  __typename?: "DeletedWorkRecord";
  id: string;
  applicationDate: TailorGqlDate;
  applicantID: string;
  contractID: string;
  recordTypeID: string;
  startedAt?: TailorGqlTime | null;
  endedAt?: TailorGqlTime | null;
  breakTime?: TailorGqlTime | null;
  lateTime?: TailorGqlTime | null;
  lateWorkingTime?: TailorGqlTime | null;
  legalOverworkingTime?: TailorGqlTime | null;
  legalWorkingTime?: TailorGqlTime | null;
  scheduledStartedAt?: TailorGqlTime | null;
  scheduledEndedAt?: TailorGqlTime | null;
  scheduledBreakTime?: TailorGqlTime | null;
  workingTime?: TailorGqlTime | null;
  holidayWorkingTime?: TailorGqlTime | null;
  inPrescribedTime?: TailorGqlTime | null;
  statutoryOverworkingTime?: TailorGqlTime | null;
  bindingTime?: TailorGqlTime | null;
  prescribedTime?: TailorGqlTime | null;
  workComment?: string | null;
  transportCostType?: Types.DeletedWorkRecordTransportCostType | null;
  stateID: string;
  currentState: string;
  deletedDate: TailorGqlDate;
  deleteReason?: string | null;
  applicant?: {
    __typename?: "Employee";
    name: string;
    employeeCode: string;
    employeeCategory?: { __typename?: "EmployeeCategory"; name: string } | null;
  } | null;
  contract?: { __typename?: "Contract"; name: string; code: string } | null;
  recordType?: {
    __typename?: "RecordType";
    id: string;
    name: string;
    isHoliday?: boolean | null;
    isCommentable: boolean;
    needTimeInput?: boolean | null;
    needLateTime?: boolean | null;
    needTransportCosts?: boolean | null;
    useUserInputPrescribedTime?: boolean | null;
    useDefaultPrescribedTime?: boolean | null;
    displayPriority?: number | null;
  } | null;
  state?: {
    __typename?: "State";
    id: string;
    name?: string | null;
    currentState?: string | null;
    histories?: Array<{
      __typename?: "StateHistory";
      id: string;
      state: string;
      action: string;
      approver: string;
      createdAt: any;
    }> | null;
  } | null;
  deletedBy?: { __typename?: "Employee"; name: string } | null;
};

export type SearchWorkRecordsQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.WorkRecordQueryInput>;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.WorkRecordOrderInput>>
    | Types.InputMaybe<Types.WorkRecordOrderInput>
  >;
}>;

export type SearchWorkRecordsQuery = {
  __typename?: "Query";
  workRecords?: {
    __typename?: "WorkRecordResult";
    total: number;
    collection: Array<{
      __typename?: "WorkRecord";
      id: string;
      applicantCategoryCode: string;
      applicantCode?: string | null;
      applicantID: string;
      applicantName?: string | null;
      applicationDate: TailorGqlDate;
      employeeCategory?: string | null;
      contractID: string;
      contractCode: string;
      contractName?: string | null;
      contractWorkplaceName?: string | null;
      recordTypeID: string;
      startedAt?: TailorGqlTime | null;
      endedAt?: TailorGqlTime | null;
      breakTime?: TailorGqlTime | null;
      lateTime?: TailorGqlTime | null;
      lateWorkingTime?: TailorGqlTime | null;
      legalOverworkingTime?: TailorGqlTime | null;
      legalWorkingTime?: TailorGqlTime | null;
      scheduledStartedAt?: TailorGqlTime | null;
      scheduledEndedAt?: TailorGqlTime | null;
      scheduledBreakTime?: TailorGqlTime | null;
      workingTime?: TailorGqlTime | null;
      holidayWorkingTime?: TailorGqlTime | null;
      inPrescribedTime?: TailorGqlTime | null;
      statutoryOverworkingTime?: TailorGqlTime | null;
      bindingTime?: TailorGqlTime | null;
      prescribedTime?: TailorGqlTime | null;
      workComment?: string | null;
      transportCostType?: Types.WorkRecordTransportCostType | null;
      stateID: string;
      currentState: string;
      applicant?: {
        __typename?: "Employee";
        name: string;
        employeeCode: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
        } | null;
      } | null;
      contract?: { __typename?: "Contract"; name: string; code: string } | null;
      recordType?: {
        __typename?: "RecordType";
        id: string;
        name: string;
        isHoliday?: boolean | null;
        isCommentable: boolean;
        needTimeInput?: boolean | null;
        needLateTime?: boolean | null;
        needTransportCosts?: boolean | null;
        useUserInputPrescribedTime?: boolean | null;
        useDefaultPrescribedTime?: boolean | null;
        displayPriority?: number | null;
      } | null;
      state?: {
        __typename?: "State";
        id: string;
        name?: string | null;
        currentState?: string | null;
        histories?: Array<{
          __typename?: "StateHistory";
          id: string;
          state: string;
          action: string;
          approver: string;
          createdAt: any;
        }> | null;
      } | null;
    }>;
  } | null;
};

export type SearchDeletedWorkRecordsQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.DeletedWorkRecordQueryInput>;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.DeletedWorkRecordOrderInput>>
    | Types.InputMaybe<Types.DeletedWorkRecordOrderInput>
  >;
}>;

export type SearchDeletedWorkRecordsQuery = {
  __typename?: "Query";
  deletedWorkRecords?: {
    __typename?: "DeletedWorkRecordResult";
    total: number;
    collection: Array<{
      __typename?: "DeletedWorkRecord";
      id: string;
      applicationDate: TailorGqlDate;
      applicantID: string;
      contractID: string;
      recordTypeID: string;
      startedAt?: TailorGqlTime | null;
      endedAt?: TailorGqlTime | null;
      breakTime?: TailorGqlTime | null;
      lateTime?: TailorGqlTime | null;
      lateWorkingTime?: TailorGqlTime | null;
      legalOverworkingTime?: TailorGqlTime | null;
      legalWorkingTime?: TailorGqlTime | null;
      scheduledStartedAt?: TailorGqlTime | null;
      scheduledEndedAt?: TailorGqlTime | null;
      scheduledBreakTime?: TailorGqlTime | null;
      workingTime?: TailorGqlTime | null;
      holidayWorkingTime?: TailorGqlTime | null;
      inPrescribedTime?: TailorGqlTime | null;
      statutoryOverworkingTime?: TailorGqlTime | null;
      bindingTime?: TailorGqlTime | null;
      prescribedTime?: TailorGqlTime | null;
      workComment?: string | null;
      transportCostType?: Types.DeletedWorkRecordTransportCostType | null;
      stateID: string;
      currentState: string;
      deletedDate: TailorGqlDate;
      deleteReason?: string | null;
      applicant?: {
        __typename?: "Employee";
        name: string;
        employeeCode: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
        } | null;
      } | null;
      contract?: { __typename?: "Contract"; name: string; code: string } | null;
      recordType?: {
        __typename?: "RecordType";
        id: string;
        name: string;
        isHoliday?: boolean | null;
        isCommentable: boolean;
        needTimeInput?: boolean | null;
        needLateTime?: boolean | null;
        needTransportCosts?: boolean | null;
        useUserInputPrescribedTime?: boolean | null;
        useDefaultPrescribedTime?: boolean | null;
        displayPriority?: number | null;
      } | null;
      state?: {
        __typename?: "State";
        id: string;
        name?: string | null;
        currentState?: string | null;
        histories?: Array<{
          __typename?: "StateHistory";
          id: string;
          state: string;
          action: string;
          approver: string;
          createdAt: any;
        }> | null;
      } | null;
      deletedBy?: { __typename?: "Employee"; name: string } | null;
    }>;
  } | null;
};

export type ApplyWorkRecordMutationVariables = Types.Exact<{
  input: Types.ApplyWorkRecordInput;
}>;

export type ApplyWorkRecordMutation = {
  __typename?: "Mutation";
  applyWorkRecord?: string | null;
};

export type UpdateWorkRecordMutationVariables = Types.Exact<{
  input: Types.EditWorkRecordInput;
}>;

export type UpdateWorkRecordMutation = {
  __typename?: "Mutation";
  editWorkRecord?: string | null;
};

export type ApproveWorkRecordMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  action: Types.Scalars["String"]["input"];
}>;

export type ApproveWorkRecordMutation = {
  __typename?: "Mutation";
  punchWorkRecord?: {
    __typename?: "PunchWorkRecordResult";
    id?: string | null;
    currentState?: string | null;
  } | null;
};

export type ProfileFragment = {
  __typename?: "User";
  id: string;
  username: string;
  displayName: string;
  userGroups: Array<{
    __typename?: "UserGroup";
    startAt?: any | null;
    endAt?: any | null;
    group?: {
      __typename?: "Group";
      id: string;
      name: string;
      code?: string | null;
      parent?: {
        __typename?: "Group";
        id: string;
        name: string;
        code?: string | null;
      } | null;
    } | null;
  }>;
  userRoles: Array<{
    __typename?: "UserRole";
    startAt?: any | null;
    endAt?: any | null;
    role?: {
      __typename?: "Role";
      id: string;
      name: string;
      roleClass: { __typename?: "RoleClass"; id: string; name?: string | null };
    } | null;
  }>;
};

export type LoginMutationVariables = Types.Exact<{
  orgName: Types.Scalars["String"]["input"];
  id: Types.Scalars["String"]["input"];
  password: Types.Scalars["String"]["input"];
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login?: {
    __typename?: "LoginPayload";
    token: string;
    user: {
      __typename?: "User";
      id: string;
      username: string;
      displayName: string;
      roles: Array<{ __typename?: "Role"; name: string }>;
      groups: Array<{ __typename?: "Group"; name: string }>;
    };
  } | null;
};

export type MeForProfileQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeForProfileQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    username: string;
    displayName: string;
    userGroups: Array<{
      __typename?: "UserGroup";
      startAt?: any | null;
      endAt?: any | null;
      group?: {
        __typename?: "Group";
        id: string;
        name: string;
        code?: string | null;
        parent?: {
          __typename?: "Group";
          id: string;
          name: string;
          code?: string | null;
        } | null;
      } | null;
    }>;
    userRoles: Array<{
      __typename?: "UserRole";
      startAt?: any | null;
      endAt?: any | null;
      role?: {
        __typename?: "Role";
        id: string;
        name: string;
        roleClass: {
          __typename?: "RoleClass";
          id: string;
          name?: string | null;
        };
      } | null;
    }>;
  } | null;
};

export type FetchEmployeeByUserIdQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FetchEmployeeByUserIdQuery = {
  __typename?: "Query";
  employees?: {
    __typename?: "EmployeeResult";
    collection: Array<{
      __typename?: "Employee";
      id: string;
      name: string;
      employeeCode: string;
      employeeCategoryID: string;
      defaultContractCode?: string | null;
      defaultOfficeCode: string;
      defaultScheduledBreakTime?: TailorGqlTime | null;
      defaultScheduledStartedAt?: TailorGqlTime | null;
      defaultScheduledEndedAt?: TailorGqlTime | null;
      joinedAt: TailorGqlDate;
      userID: string;
      employeeCategory?: {
        __typename?: "EmployeeCategory";
        name: string;
        code: number;
      } | null;
      paymentCategory?: {
        __typename?: "PaymentCategory";
        id: string;
        name: string;
        code: number;
      } | null;
      user?: {
        __typename?: "User";
        userRoles: Array<{
          __typename?: "UserRole";
          role?: { __typename?: "Role"; name: string } | null;
        }>;
        userGroups: Array<{
          __typename?: "UserGroup";
          group?: { __typename?: "Group"; name: string } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type FetchEmployeeQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FetchEmployeeQuery = {
  __typename?: "Query";
  employee?: {
    __typename?: "Employee";
    id: string;
    name: string;
    employeeCode: string;
    employeeCategoryID: string;
    defaultContractCode?: string | null;
    defaultOfficeCode: string;
    defaultScheduledBreakTime?: TailorGqlTime | null;
    defaultScheduledStartedAt?: TailorGqlTime | null;
    defaultScheduledEndedAt?: TailorGqlTime | null;
    joinedAt: TailorGqlDate;
    userID: string;
    employeeCategory?: {
      __typename?: "EmployeeCategory";
      name: string;
      code: number;
    } | null;
    paymentCategory?: {
      __typename?: "PaymentCategory";
      id: string;
      name: string;
      code: number;
    } | null;
    user?: {
      __typename?: "User";
      userRoles: Array<{
        __typename?: "UserRole";
        role?: { __typename?: "Role"; name: string } | null;
      }>;
      userGroups: Array<{
        __typename?: "UserGroup";
        group?: { __typename?: "Group"; name: string } | null;
      }>;
    } | null;
  } | null;
};

export type SearchEmployeesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.EmployeeQueryInput>;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  order?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.EmployeeOrderInput>>
    | Types.InputMaybe<Types.EmployeeOrderInput>
  >;
}>;

export type SearchEmployeesQuery = {
  __typename?: "Query";
  employees?: {
    __typename?: "EmployeeResult";
    total: number;
    collection: Array<{
      __typename?: "Employee";
      id: string;
      name: string;
      employeeCode: string;
      employeeCategoryID: string;
      defaultContractCode?: string | null;
      defaultOfficeCode: string;
      defaultScheduledBreakTime?: TailorGqlTime | null;
      defaultScheduledStartedAt?: TailorGqlTime | null;
      defaultScheduledEndedAt?: TailorGqlTime | null;
      joinedAt: TailorGqlDate;
      userID: string;
      employeeCategory?: {
        __typename?: "EmployeeCategory";
        name: string;
        code: number;
      } | null;
      paymentCategory?: {
        __typename?: "PaymentCategory";
        id: string;
        name: string;
        code: number;
      } | null;
      user?: {
        __typename?: "User";
        userRoles: Array<{
          __typename?: "UserRole";
          role?: { __typename?: "Role"; name: string } | null;
        }>;
        userGroups: Array<{
          __typename?: "UserGroup";
          group?: { __typename?: "Group"; name: string } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type UpdateEmployeeMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  input: Types.EmployeeUpdateInput;
}>;

export type UpdateEmployeeMutation = {
  __typename?: "Mutation";
  updateEmployee?: { __typename?: "Employee"; id: string } | null;
};

export type ResetPasswordMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  password: Types.Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword?: boolean | null;
};

export type MoveWorkrecordToDeletedWorkrecordMutationVariables = Types.Exact<{
  currentState: Types.Scalars["String"]["input"];
  deleteReason?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  deletedDate: Types.Scalars["Date"]["input"];
  id: Types.Scalars["ID"]["input"];
}>;

export type MoveWorkrecordToDeletedWorkrecordMutation = {
  __typename?: "Mutation";
  moveToDeletedWorkrecord?: boolean | null;
};

export type EmployeeWorkingTimeItemFragment = {
  __typename?: "EmployeeWorkingTime";
  id: string;
  employeeID: string;
  value: TailorGqlTime;
  startDate: TailorGqlDate;
  endDate: TailorGqlDate;
  employee?: {
    __typename?: "Employee";
    id: string;
    name: string;
    employeeCode: string;
    employeeCategoryID: string;
    defaultContractCode?: string | null;
    defaultOfficeCode: string;
    defaultScheduledBreakTime?: TailorGqlTime | null;
    defaultScheduledStartedAt?: TailorGqlTime | null;
    defaultScheduledEndedAt?: TailorGqlTime | null;
    joinedAt: TailorGqlDate;
    userID: string;
    employeeCategory?: {
      __typename?: "EmployeeCategory";
      name: string;
      code: number;
    } | null;
    paymentCategory?: {
      __typename?: "PaymentCategory";
      id: string;
      name: string;
      code: number;
    } | null;
    user?: {
      __typename?: "User";
      userRoles: Array<{
        __typename?: "UserRole";
        role?: { __typename?: "Role"; name: string } | null;
      }>;
      userGroups: Array<{
        __typename?: "UserGroup";
        group?: { __typename?: "Group"; name: string } | null;
      }>;
    } | null;
  } | null;
};

export type SettingEmployeeWorkingTimesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.EmployeeWorkingTimeQueryInput>;
  sort?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.EmployeeWorkingTimeOrderInput>>
    | Types.InputMaybe<Types.EmployeeWorkingTimeOrderInput>
  >;
  size?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  from?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type SettingEmployeeWorkingTimesQuery = {
  __typename?: "Query";
  employeeWorkingTimes?: {
    __typename?: "EmployeeWorkingTimeResult";
    total: number;
    collection: Array<{
      __typename?: "EmployeeWorkingTime";
      id: string;
      employeeID: string;
      value: TailorGqlTime;
      startDate: TailorGqlDate;
      endDate: TailorGqlDate;
      employee?: {
        __typename?: "Employee";
        id: string;
        name: string;
        employeeCode: string;
        employeeCategoryID: string;
        defaultContractCode?: string | null;
        defaultOfficeCode: string;
        defaultScheduledBreakTime?: TailorGqlTime | null;
        defaultScheduledStartedAt?: TailorGqlTime | null;
        defaultScheduledEndedAt?: TailorGqlTime | null;
        joinedAt: TailorGqlDate;
        userID: string;
        employeeCategory?: {
          __typename?: "EmployeeCategory";
          name: string;
          code: number;
        } | null;
        paymentCategory?: {
          __typename?: "PaymentCategory";
          id: string;
          name: string;
          code: number;
        } | null;
        user?: {
          __typename?: "User";
          userRoles: Array<{
            __typename?: "UserRole";
            role?: { __typename?: "Role"; name: string } | null;
          }>;
          userGroups: Array<{
            __typename?: "UserGroup";
            group?: { __typename?: "Group"; name: string } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type EditSettingEmployeeWorkingTimeMutationVariables = Types.Exact<{
  input: Types.EditEmployeeWorkingTimeInput;
}>;

export type EditSettingEmployeeWorkingTimeMutation = {
  __typename?: "Mutation";
  editEmployeeWorkingTime?: string | null;
};

export type AddSettingEmployeeWorkingTimeMutationVariables = Types.Exact<{
  input: Types.AddEmployeeWorkingTimeInput;
}>;

export type AddSettingEmployeeWorkingTimeMutation = {
  __typename?: "Mutation";
  addEmployeeWorkingTime?: string | null;
};

export type DeleteSettingEmployeeWorkingTimeMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type DeleteSettingEmployeeWorkingTimeMutation = {
  __typename?: "Mutation";
  deleteEmployeeWorkingTime?: boolean | null;
};

export type ContractSummariesQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ContractSummariesInput>;
}>;

export type ContractSummariesQuery = {
  __typename?: "Query";
  contractSummaries?: {
    __typename?: "ContractSummariesResult";
    total: number;
    collection?: Array<{
      __typename?: "ContractSummary";
      contract?: {
        __typename?: "BasicContractInfo";
        id: string;
        name?: string | null;
        workplaceName?: string | null;
        code?: string | null;
        salesOffice?: {
          __typename?: "BasicSalesOfficeInfo";
          name?: string | null;
        } | null;
      } | null;
      workRecordSummaries?: {
        __typename?: "WorkRecordSummaries";
        holidayCount?: number | null;
        collection?: Array<{
          __typename?: "WorkRecordSummary";
          currentState?: string | null;
          count?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type EmployeeSummariesQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.EmployeeSummariesInput>;
}>;

export type EmployeeSummariesQuery = {
  __typename?: "Query";
  employeeSummaries?: {
    __typename?: "EmployeeSummariesResult";
    total: number;
    collection?: Array<{
      __typename?: "EmployeeSummary";
      employee?: {
        __typename?: "BasicEmployeeInfo";
        id?: string | null;
        name: string;
        code?: string | null;
      } | null;
      workRecordSummaries?: {
        __typename?: "WorkRecordSummaries";
        holidayCount?: number | null;
        collection?: Array<{
          __typename?: "WorkRecordSummary";
          currentState?: string | null;
          count?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CloseMonthMutationVariables = Types.Exact<{
  input: Types.CloseMonthInput;
}>;

export type CloseMonthMutation = {
  __typename?: "Mutation";
  closeMonth?: string | null;
};

export type FetchEmployeeCategoriesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type FetchEmployeeCategoriesQuery = {
  __typename?: "Query";
  employeeCategories?: {
    __typename?: "EmployeeCategoryResult";
    collection: Array<{
      __typename?: "EmployeeCategory";
      id: string;
      name: string;
      code: number;
    }>;
  } | null;
};

export type HolidayRecordQueryVariables = Types.Exact<{ [key: string]: never }>;

export type HolidayRecordQuery = {
  __typename?: "Query";
  recordTypes?: {
    __typename?: "RecordTypeResult";
    collection: Array<{ __typename?: "RecordType"; id: string; name: string }>;
  } | null;
};

export type CloseStatusesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.CloseStatusQueryInput>;
}>;

export type CloseStatusesQuery = {
  __typename?: "Query";
  closeStatuses?: {
    __typename?: "CloseStatusResult";
    total: number;
    items: number;
    size: number;
    from: number;
    collection: Array<{
      __typename?: "CloseStatus";
      status: Types.CloseStatusStatus;
      id: string;
      contractID: string;
      closeDate: TailorGqlDate;
      updatedByID?: string | null;
      executedAt?: any | null;
      executedById?: string | null;
    }>;
  } | null;
};

export const EmployeeItemFragmentDoc = gql`
  fragment EmployeeItem on Employee {
    id
    name
    employeeCode
    employeeCategoryID
    employeeCategory {
      name
      code
    }
    defaultContractCode
    defaultOfficeCode
    defaultScheduledBreakTime
    defaultScheduledStartedAt
    defaultScheduledEndedAt
    joinedAt
    paymentCategory {
      id
      name
      code
    }
    userID
    user {
      userRoles {
        role {
          name
        }
      }
      userGroups {
        group {
          name
        }
      }
    }
  }
`;
export const SalesOfficeItemFragmentDoc = gql`
  fragment SalesOfficeItem on SalesOffice {
    id
    groupID
    code
    name
    managers {
      employeeID
      startAt
      endAt
      roleType
      userGroupID
      employee {
        ...EmployeeItem
      }
    }
  }
  ${EmployeeItemFragmentDoc}
`;
export const ContractItemFragmentDoc = gql`
  fragment ContractItem on Contract {
    id
    name
    code
    workplaceName
    salesOffice {
      ...SalesOfficeItem
    }
  }
  ${SalesOfficeItemFragmentDoc}
`;
export const ManagerItemFragmentDoc = gql`
  fragment ManagerItem on ContractManagers {
    employeeID
    startAt
    endAt
    roleType
    userRoleID
    employee {
      ...EmployeeItem
    }
  }
  ${EmployeeItemFragmentDoc}
`;
export const ContractDetailItemFragmentDoc = gql`
  fragment ContractDetailItem on Contract {
    id
    name
    code
    workplaceName
    managers {
      ...ManagerItem
    }
  }
  ${ManagerItemFragmentDoc}
`;
export const RecordTypeItemFragmentDoc = gql`
  fragment RecordTypeItem on RecordType {
    id
    name
    isHoliday
    isCommentable
    needTimeInput
    needLateTime
    needTransportCosts
    useUserInputPrescribedTime
    useDefaultPrescribedTime
    displayPriority
  }
`;
export const WorkRecordItemFragmentDoc = gql`
  fragment WorkRecordItem on WorkRecord {
    id
    applicantCategoryCode
    applicantCode
    applicantID
    applicantName
    applicationDate
    applicant {
      name
      employeeCode
      employeeCategory {
        name
      }
    }
    employeeCategory
    contractID
    contractCode
    contractName
    contractWorkplaceName
    contract {
      name
      code
    }
    recordTypeID
    recordType {
      ...RecordTypeItem
    }
    startedAt
    endedAt
    breakTime
    lateTime
    lateWorkingTime
    legalOverworkingTime
    legalWorkingTime
    scheduledStartedAt
    scheduledEndedAt
    scheduledBreakTime
    workingTime
    holidayWorkingTime
    inPrescribedTime
    statutoryOverworkingTime
    bindingTime
    prescribedTime
    legalOverworkingTime
    workComment
    transportCostType
    stateID
    currentState
    state {
      id
      name
      currentState
      histories {
        id
        state
        action
        approver
        createdAt
      }
    }
  }
  ${RecordTypeItemFragmentDoc}
`;
export const DeletedWorkRecordItemFragmentDoc = gql`
  fragment DeletedWorkRecordItem on DeletedWorkRecord {
    id
    applicationDate
    applicantID
    applicant {
      name
      employeeCode
      employeeCategory {
        name
      }
    }
    contractID
    contract {
      name
      code
    }
    recordTypeID
    recordType {
      ...RecordTypeItem
    }
    startedAt
    endedAt
    breakTime
    lateTime
    lateWorkingTime
    legalOverworkingTime
    legalWorkingTime
    scheduledStartedAt
    scheduledEndedAt
    scheduledBreakTime
    workingTime
    holidayWorkingTime
    inPrescribedTime
    statutoryOverworkingTime
    bindingTime
    prescribedTime
    legalOverworkingTime
    workComment
    transportCostType
    stateID
    currentState
    state {
      id
      name
      currentState
      histories {
        id
        state
        action
        approver
        createdAt
      }
    }
    deletedBy {
      name
    }
    deletedDate
    deleteReason
  }
  ${RecordTypeItemFragmentDoc}
`;
export const OfficeItemFragmentDoc = gql`
  fragment OfficeItem on Group {
    id
    name
    code
    parent {
      id
      name
      code
    }
  }
`;
export const RoleItemFragmentDoc = gql`
  fragment RoleItem on Role {
    id
    name
    roleClass {
      id
      name
    }
  }
`;
export const ProfileFragmentDoc = gql`
  fragment Profile on User {
    id
    username
    displayName
    userGroups {
      startAt
      endAt
      group {
        ...OfficeItem
      }
    }
    userRoles {
      startAt
      endAt
      role {
        ...RoleItem
      }
    }
  }
  ${OfficeItemFragmentDoc}
  ${RoleItemFragmentDoc}
`;
export const EmployeeWorkingTimeItemFragmentDoc = gql`
  fragment EmployeeWorkingTimeItem on EmployeeWorkingTime {
    id
    employeeID
    value
    startDate
    endDate
    employee {
      ...EmployeeItem
    }
  }
  ${EmployeeItemFragmentDoc}
`;
export const FetchOfficeDocument = gql`
  query fetchOffice($id: ID!) {
    office: group(id: $id) {
      ...OfficeItem
    }
  }
  ${OfficeItemFragmentDoc}
`;

/**
 * __useFetchOfficeQuery__
 *
 * To run a query within a React component, call `useFetchOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfficeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchOfficeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchOfficeQuery,
    FetchOfficeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchOfficeQuery, FetchOfficeQueryVariables>(
    FetchOfficeDocument,
    options,
  );
}
export function useFetchOfficeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchOfficeQuery,
    FetchOfficeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchOfficeQuery, FetchOfficeQueryVariables>(
    FetchOfficeDocument,
    options,
  );
}
export type FetchOfficeQueryHookResult = ReturnType<typeof useFetchOfficeQuery>;
export type FetchOfficeLazyQueryHookResult = ReturnType<
  typeof useFetchOfficeLazyQuery
>;
export type FetchOfficeQueryResult = Apollo.QueryResult<
  FetchOfficeQuery,
  FetchOfficeQueryVariables
>;
export const SearchOfficesDocument = gql`
  query searchOffices(
    $query: GroupQueryInput
    $from: Int
    $size: Int
    $order: [GroupOrderInput]
  ) {
    offices: groups(query: $query, from: $from, size: $size, order: $order) {
      collection {
        ...OfficeItem
      }
      total
    }
  }
  ${OfficeItemFragmentDoc}
`;

/**
 * __useSearchOfficesQuery__
 *
 * To run a query within a React component, call `useSearchOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOfficesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchOfficesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchOfficesQuery,
    SearchOfficesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchOfficesQuery, SearchOfficesQueryVariables>(
    SearchOfficesDocument,
    options,
  );
}
export function useSearchOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchOfficesQuery,
    SearchOfficesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchOfficesQuery, SearchOfficesQueryVariables>(
    SearchOfficesDocument,
    options,
  );
}
export type SearchOfficesQueryHookResult = ReturnType<
  typeof useSearchOfficesQuery
>;
export type SearchOfficesLazyQueryHookResult = ReturnType<
  typeof useSearchOfficesLazyQuery
>;
export type SearchOfficesQueryResult = Apollo.QueryResult<
  SearchOfficesQuery,
  SearchOfficesQueryVariables
>;
export const SearchSalesOfficesDocument = gql`
  query searchSalesOffices(
    $query: SalesOfficeQueryInput
    $from: Int
    $size: Int
    $order: [SalesOfficeOrderInput]
  ) {
    salesOffices: salesOffices(
      query: $query
      from: $from
      size: $size
      order: $order
    ) {
      collection {
        ...SalesOfficeItem
      }
      total
    }
  }
  ${SalesOfficeItemFragmentDoc}
`;

/**
 * __useSearchSalesOfficesQuery__
 *
 * To run a query within a React component, call `useSearchSalesOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSalesOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSalesOfficesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchSalesOfficesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchSalesOfficesQuery,
    SearchSalesOfficesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchSalesOfficesQuery,
    SearchSalesOfficesQueryVariables
  >(SearchSalesOfficesDocument, options);
}
export function useSearchSalesOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSalesOfficesQuery,
    SearchSalesOfficesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchSalesOfficesQuery,
    SearchSalesOfficesQueryVariables
  >(SearchSalesOfficesDocument, options);
}
export type SearchSalesOfficesQueryHookResult = ReturnType<
  typeof useSearchSalesOfficesQuery
>;
export type SearchSalesOfficesLazyQueryHookResult = ReturnType<
  typeof useSearchSalesOfficesLazyQuery
>;
export type SearchSalesOfficesQueryResult = Apollo.QueryResult<
  SearchSalesOfficesQuery,
  SearchSalesOfficesQueryVariables
>;
export const SearchSalesOfficesOnMasterDocument = gql`
  query searchSalesOfficesOnMaster(
    $query: SalesOfficeQueryInput
    $from: Int
    $size: Int
    $order: [SalesOfficeOrderInput]
  ) {
    salesOffices: salesOffices(
      query: $query
      from: $from
      size: $size
      order: $order
    ) {
      collection {
        id
        groupID
        code
        name
      }
      total
    }
  }
`;

/**
 * __useSearchSalesOfficesOnMasterQuery__
 *
 * To run a query within a React component, call `useSearchSalesOfficesOnMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSalesOfficesOnMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSalesOfficesOnMasterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchSalesOfficesOnMasterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchSalesOfficesOnMasterQuery,
    SearchSalesOfficesOnMasterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchSalesOfficesOnMasterQuery,
    SearchSalesOfficesOnMasterQueryVariables
  >(SearchSalesOfficesOnMasterDocument, options);
}
export function useSearchSalesOfficesOnMasterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchSalesOfficesOnMasterQuery,
    SearchSalesOfficesOnMasterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchSalesOfficesOnMasterQuery,
    SearchSalesOfficesOnMasterQueryVariables
  >(SearchSalesOfficesOnMasterDocument, options);
}
export type SearchSalesOfficesOnMasterQueryHookResult = ReturnType<
  typeof useSearchSalesOfficesOnMasterQuery
>;
export type SearchSalesOfficesOnMasterLazyQueryHookResult = ReturnType<
  typeof useSearchSalesOfficesOnMasterLazyQuery
>;
export type SearchSalesOfficesOnMasterQueryResult = Apollo.QueryResult<
  SearchSalesOfficesOnMasterQuery,
  SearchSalesOfficesOnMasterQueryVariables
>;
export const FetchSalesOfficeDocument = gql`
  query fetchSalesOffice($id: ID!) {
    salesOffice(id: $id) {
      ...SalesOfficeItem
    }
  }
  ${SalesOfficeItemFragmentDoc}
`;

/**
 * __useFetchSalesOfficeQuery__
 *
 * To run a query within a React component, call `useFetchSalesOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalesOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalesOfficeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchSalesOfficeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSalesOfficeQuery,
    FetchSalesOfficeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchSalesOfficeQuery, FetchSalesOfficeQueryVariables>(
    FetchSalesOfficeDocument,
    options,
  );
}
export function useFetchSalesOfficeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSalesOfficeQuery,
    FetchSalesOfficeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchSalesOfficeQuery,
    FetchSalesOfficeQueryVariables
  >(FetchSalesOfficeDocument, options);
}
export type FetchSalesOfficeQueryHookResult = ReturnType<
  typeof useFetchSalesOfficeQuery
>;
export type FetchSalesOfficeLazyQueryHookResult = ReturnType<
  typeof useFetchSalesOfficeLazyQuery
>;
export type FetchSalesOfficeQueryResult = Apollo.QueryResult<
  FetchSalesOfficeQuery,
  FetchSalesOfficeQueryVariables
>;
export const UpdateSalesOfficeDocument = gql`
  mutation updateSalesOffice($id: ID!, $input: SalesOfficeUpdateInput!) {
    updateSalesOffice(id: $id, input: $input) {
      ...SalesOfficeItem
    }
  }
  ${SalesOfficeItemFragmentDoc}
`;
export type UpdateSalesOfficeMutationFn = Apollo.MutationFunction<
  UpdateSalesOfficeMutation,
  UpdateSalesOfficeMutationVariables
>;

/**
 * __useUpdateSalesOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateSalesOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesOfficeMutation, { data, loading, error }] = useUpdateSalesOfficeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesOfficeMutation,
    UpdateSalesOfficeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSalesOfficeMutation,
    UpdateSalesOfficeMutationVariables
  >(UpdateSalesOfficeDocument, options);
}
export type UpdateSalesOfficeMutationHookResult = ReturnType<
  typeof useUpdateSalesOfficeMutation
>;
export type UpdateSalesOfficeMutationResult =
  Apollo.MutationResult<UpdateSalesOfficeMutation>;
export type UpdateSalesOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesOfficeMutation,
  UpdateSalesOfficeMutationVariables
>;
export const AddUserToSalesOfficeDocument = gql`
  mutation addUserToSalesOffice($input: AddUserToSalesOfficeInput!) {
    addUserToSalesOffice(input: $input)
  }
`;
export type AddUserToSalesOfficeMutationFn = Apollo.MutationFunction<
  AddUserToSalesOfficeMutation,
  AddUserToSalesOfficeMutationVariables
>;

/**
 * __useAddUserToSalesOfficeMutation__
 *
 * To run a mutation, you first call `useAddUserToSalesOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToSalesOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToSalesOfficeMutation, { data, loading, error }] = useAddUserToSalesOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserToSalesOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToSalesOfficeMutation,
    AddUserToSalesOfficeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserToSalesOfficeMutation,
    AddUserToSalesOfficeMutationVariables
  >(AddUserToSalesOfficeDocument, options);
}
export type AddUserToSalesOfficeMutationHookResult = ReturnType<
  typeof useAddUserToSalesOfficeMutation
>;
export type AddUserToSalesOfficeMutationResult =
  Apollo.MutationResult<AddUserToSalesOfficeMutation>;
export type AddUserToSalesOfficeMutationOptions = Apollo.BaseMutationOptions<
  AddUserToSalesOfficeMutation,
  AddUserToSalesOfficeMutationVariables
>;
export const DelUserFromSalesOfficeDocument = gql`
  mutation delUserFromSalesOffice($input: DeleteUserFromSalesOfficeInput!) {
    deleteUserFromSalesOffice(input: $input)
  }
`;
export type DelUserFromSalesOfficeMutationFn = Apollo.MutationFunction<
  DelUserFromSalesOfficeMutation,
  DelUserFromSalesOfficeMutationVariables
>;

/**
 * __useDelUserFromSalesOfficeMutation__
 *
 * To run a mutation, you first call `useDelUserFromSalesOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelUserFromSalesOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delUserFromSalesOfficeMutation, { data, loading, error }] = useDelUserFromSalesOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelUserFromSalesOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DelUserFromSalesOfficeMutation,
    DelUserFromSalesOfficeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DelUserFromSalesOfficeMutation,
    DelUserFromSalesOfficeMutationVariables
  >(DelUserFromSalesOfficeDocument, options);
}
export type DelUserFromSalesOfficeMutationHookResult = ReturnType<
  typeof useDelUserFromSalesOfficeMutation
>;
export type DelUserFromSalesOfficeMutationResult =
  Apollo.MutationResult<DelUserFromSalesOfficeMutation>;
export type DelUserFromSalesOfficeMutationOptions = Apollo.BaseMutationOptions<
  DelUserFromSalesOfficeMutation,
  DelUserFromSalesOfficeMutationVariables
>;
export const SearchContractsDocument = gql`
  query searchContracts(
    $query: ContractQueryInput
    $order: [ContractOrderInput]
    $from: Int
    $size: Int
  ) {
    contracts(query: $query, from: $from, size: $size, order: $order) {
      collection {
        id
        name
        workplaceName
        code
      }
      total
    }
  }
`;

/**
 * __useSearchContractsQuery__
 *
 * To run a query within a React component, call `useSearchContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContractsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSearchContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchContractsQuery,
    SearchContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchContractsQuery, SearchContractsQueryVariables>(
    SearchContractsDocument,
    options,
  );
}
export function useSearchContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchContractsQuery,
    SearchContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchContractsQuery,
    SearchContractsQueryVariables
  >(SearchContractsDocument, options);
}
export type SearchContractsQueryHookResult = ReturnType<
  typeof useSearchContractsQuery
>;
export type SearchContractsLazyQueryHookResult = ReturnType<
  typeof useSearchContractsLazyQuery
>;
export type SearchContractsQueryResult = Apollo.QueryResult<
  SearchContractsQuery,
  SearchContractsQueryVariables
>;
export const SearchContractsOnMasterDocument = gql`
  query searchContractsOnMaster(
    $query: ContractQueryInput
    $order: [ContractOrderInput]
    $from: Int
    $size: Int
  ) {
    contracts(query: $query, from: $from, size: $size, order: $order) {
      collection {
        id
        name
        code
        workplaceName
        salesOffice {
          id
          name
          code
        }
      }
      total
    }
  }
`;

/**
 * __useSearchContractsOnMasterQuery__
 *
 * To run a query within a React component, call `useSearchContractsOnMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContractsOnMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContractsOnMasterQuery({
 *   variables: {
 *      query: // value for 'query'
 *      order: // value for 'order'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSearchContractsOnMasterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchContractsOnMasterQuery,
    SearchContractsOnMasterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchContractsOnMasterQuery,
    SearchContractsOnMasterQueryVariables
  >(SearchContractsOnMasterDocument, options);
}
export function useSearchContractsOnMasterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchContractsOnMasterQuery,
    SearchContractsOnMasterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchContractsOnMasterQuery,
    SearchContractsOnMasterQueryVariables
  >(SearchContractsOnMasterDocument, options);
}
export type SearchContractsOnMasterQueryHookResult = ReturnType<
  typeof useSearchContractsOnMasterQuery
>;
export type SearchContractsOnMasterLazyQueryHookResult = ReturnType<
  typeof useSearchContractsOnMasterLazyQuery
>;
export type SearchContractsOnMasterQueryResult = Apollo.QueryResult<
  SearchContractsOnMasterQuery,
  SearchContractsOnMasterQueryVariables
>;
export const FetchContractDocument = gql`
  query fetchContract($id: ID!) {
    contract(id: $id) {
      ...ContractDetailItem
    }
  }
  ${ContractDetailItemFragmentDoc}
`;

/**
 * __useFetchContractQuery__
 *
 * To run a query within a React component, call `useFetchContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchContractQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchContractQuery,
    FetchContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContractQuery, FetchContractQueryVariables>(
    FetchContractDocument,
    options,
  );
}
export function useFetchContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchContractQuery,
    FetchContractQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContractQuery, FetchContractQueryVariables>(
    FetchContractDocument,
    options,
  );
}
export type FetchContractQueryHookResult = ReturnType<
  typeof useFetchContractQuery
>;
export type FetchContractLazyQueryHookResult = ReturnType<
  typeof useFetchContractLazyQuery
>;
export type FetchContractQueryResult = Apollo.QueryResult<
  FetchContractQuery,
  FetchContractQueryVariables
>;
export const UpdateContractDocument = gql`
  mutation UpdateContract($id: ID!, $input: ContractUpdateInput!) {
    updateContract(id: $id, input: $input) {
      ...ContractDetailItem
    }
  }
  ${ContractDetailItemFragmentDoc}
`;
export type UpdateContractMutationFn = Apollo.MutationFunction<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractMutation,
    UpdateContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractMutation,
    UpdateContractMutationVariables
  >(UpdateContractDocument, options);
}
export type UpdateContractMutationHookResult = ReturnType<
  typeof useUpdateContractMutation
>;
export type UpdateContractMutationResult =
  Apollo.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;
export const AddUserToContractDocument = gql`
  mutation addUserToContract($input: AddUserToContractInput!) {
    addUserToContract(input: $input)
  }
`;
export type AddUserToContractMutationFn = Apollo.MutationFunction<
  AddUserToContractMutation,
  AddUserToContractMutationVariables
>;

/**
 * __useAddUserToContractMutation__
 *
 * To run a mutation, you first call `useAddUserToContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToContractMutation, { data, loading, error }] = useAddUserToContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserToContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToContractMutation,
    AddUserToContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserToContractMutation,
    AddUserToContractMutationVariables
  >(AddUserToContractDocument, options);
}
export type AddUserToContractMutationHookResult = ReturnType<
  typeof useAddUserToContractMutation
>;
export type AddUserToContractMutationResult =
  Apollo.MutationResult<AddUserToContractMutation>;
export type AddUserToContractMutationOptions = Apollo.BaseMutationOptions<
  AddUserToContractMutation,
  AddUserToContractMutationVariables
>;
export const DelUserFromContractDocument = gql`
  mutation delUserFromContract($input: DeleteUserFromContractInput!) {
    deleteUserFromContract(input: $input)
  }
`;
export type DelUserFromContractMutationFn = Apollo.MutationFunction<
  DelUserFromContractMutation,
  DelUserFromContractMutationVariables
>;

/**
 * __useDelUserFromContractMutation__
 *
 * To run a mutation, you first call `useDelUserFromContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelUserFromContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [delUserFromContractMutation, { data, loading, error }] = useDelUserFromContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelUserFromContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DelUserFromContractMutation,
    DelUserFromContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DelUserFromContractMutation,
    DelUserFromContractMutationVariables
  >(DelUserFromContractDocument, options);
}
export type DelUserFromContractMutationHookResult = ReturnType<
  typeof useDelUserFromContractMutation
>;
export type DelUserFromContractMutationResult =
  Apollo.MutationResult<DelUserFromContractMutation>;
export type DelUserFromContractMutationOptions = Apollo.BaseMutationOptions<
  DelUserFromContractMutation,
  DelUserFromContractMutationVariables
>;
export const FetchRecordTypesDocument = gql`
  query fetchRecordTypes($order: [RecordTypeOrderInput]) {
    recordTypes(order: $order) {
      collection {
        ...RecordTypeItem
      }
    }
  }
  ${RecordTypeItemFragmentDoc}
`;

/**
 * __useFetchRecordTypesQuery__
 *
 * To run a query within a React component, call `useFetchRecordTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecordTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecordTypesQuery({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFetchRecordTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchRecordTypesQuery,
    FetchRecordTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchRecordTypesQuery, FetchRecordTypesQueryVariables>(
    FetchRecordTypesDocument,
    options,
  );
}
export function useFetchRecordTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRecordTypesQuery,
    FetchRecordTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchRecordTypesQuery,
    FetchRecordTypesQueryVariables
  >(FetchRecordTypesDocument, options);
}
export type FetchRecordTypesQueryHookResult = ReturnType<
  typeof useFetchRecordTypesQuery
>;
export type FetchRecordTypesLazyQueryHookResult = ReturnType<
  typeof useFetchRecordTypesLazyQuery
>;
export type FetchRecordTypesQueryResult = Apollo.QueryResult<
  FetchRecordTypesQuery,
  FetchRecordTypesQueryVariables
>;
export const SearchApplicationDatesDocument = gql`
  query searchApplicationDates($query: WorkRecordQueryInput) {
    workRecords(query: $query) {
      collection {
        applicationDate
      }
    }
  }
`;

/**
 * __useSearchApplicationDatesQuery__
 *
 * To run a query within a React component, call `useSearchApplicationDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchApplicationDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchApplicationDatesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchApplicationDatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchApplicationDatesQuery,
    SearchApplicationDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchApplicationDatesQuery,
    SearchApplicationDatesQueryVariables
  >(SearchApplicationDatesDocument, options);
}
export function useSearchApplicationDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchApplicationDatesQuery,
    SearchApplicationDatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchApplicationDatesQuery,
    SearchApplicationDatesQueryVariables
  >(SearchApplicationDatesDocument, options);
}
export type SearchApplicationDatesQueryHookResult = ReturnType<
  typeof useSearchApplicationDatesQuery
>;
export type SearchApplicationDatesLazyQueryHookResult = ReturnType<
  typeof useSearchApplicationDatesLazyQuery
>;
export type SearchApplicationDatesQueryResult = Apollo.QueryResult<
  SearchApplicationDatesQuery,
  SearchApplicationDatesQueryVariables
>;
export const SearchWorkRecordsDocument = gql`
  query searchWorkRecords(
    $query: WorkRecordQueryInput
    $from: Int
    $size: Int
    $order: [WorkRecordOrderInput]
  ) {
    workRecords(query: $query, size: $size, from: $from, order: $order) {
      collection {
        ...WorkRecordItem
      }
      total
    }
  }
  ${WorkRecordItemFragmentDoc}
`;

/**
 * __useSearchWorkRecordsQuery__
 *
 * To run a query within a React component, call `useSearchWorkRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkRecordsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchWorkRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchWorkRecordsQuery,
    SearchWorkRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchWorkRecordsQuery,
    SearchWorkRecordsQueryVariables
  >(SearchWorkRecordsDocument, options);
}
export function useSearchWorkRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchWorkRecordsQuery,
    SearchWorkRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchWorkRecordsQuery,
    SearchWorkRecordsQueryVariables
  >(SearchWorkRecordsDocument, options);
}
export type SearchWorkRecordsQueryHookResult = ReturnType<
  typeof useSearchWorkRecordsQuery
>;
export type SearchWorkRecordsLazyQueryHookResult = ReturnType<
  typeof useSearchWorkRecordsLazyQuery
>;
export type SearchWorkRecordsQueryResult = Apollo.QueryResult<
  SearchWorkRecordsQuery,
  SearchWorkRecordsQueryVariables
>;
export const SearchDeletedWorkRecordsDocument = gql`
  query searchDeletedWorkRecords(
    $query: DeletedWorkRecordQueryInput
    $from: Int
    $size: Int
    $order: [DeletedWorkRecordOrderInput]
  ) {
    deletedWorkRecords(query: $query, size: $size, from: $from, order: $order) {
      collection {
        ...DeletedWorkRecordItem
      }
      total
    }
  }
  ${DeletedWorkRecordItemFragmentDoc}
`;

/**
 * __useSearchDeletedWorkRecordsQuery__
 *
 * To run a query within a React component, call `useSearchDeletedWorkRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDeletedWorkRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDeletedWorkRecordsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchDeletedWorkRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchDeletedWorkRecordsQuery,
    SearchDeletedWorkRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchDeletedWorkRecordsQuery,
    SearchDeletedWorkRecordsQueryVariables
  >(SearchDeletedWorkRecordsDocument, options);
}
export function useSearchDeletedWorkRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchDeletedWorkRecordsQuery,
    SearchDeletedWorkRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchDeletedWorkRecordsQuery,
    SearchDeletedWorkRecordsQueryVariables
  >(SearchDeletedWorkRecordsDocument, options);
}
export type SearchDeletedWorkRecordsQueryHookResult = ReturnType<
  typeof useSearchDeletedWorkRecordsQuery
>;
export type SearchDeletedWorkRecordsLazyQueryHookResult = ReturnType<
  typeof useSearchDeletedWorkRecordsLazyQuery
>;
export type SearchDeletedWorkRecordsQueryResult = Apollo.QueryResult<
  SearchDeletedWorkRecordsQuery,
  SearchDeletedWorkRecordsQueryVariables
>;
export const ApplyWorkRecordDocument = gql`
  mutation applyWorkRecord($input: ApplyWorkRecordInput!) {
    applyWorkRecord(input: $input)
  }
`;
export type ApplyWorkRecordMutationFn = Apollo.MutationFunction<
  ApplyWorkRecordMutation,
  ApplyWorkRecordMutationVariables
>;

/**
 * __useApplyWorkRecordMutation__
 *
 * To run a mutation, you first call `useApplyWorkRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyWorkRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyWorkRecordMutation, { data, loading, error }] = useApplyWorkRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyWorkRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyWorkRecordMutation,
    ApplyWorkRecordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyWorkRecordMutation,
    ApplyWorkRecordMutationVariables
  >(ApplyWorkRecordDocument, options);
}
export type ApplyWorkRecordMutationHookResult = ReturnType<
  typeof useApplyWorkRecordMutation
>;
export type ApplyWorkRecordMutationResult =
  Apollo.MutationResult<ApplyWorkRecordMutation>;
export type ApplyWorkRecordMutationOptions = Apollo.BaseMutationOptions<
  ApplyWorkRecordMutation,
  ApplyWorkRecordMutationVariables
>;
export const UpdateWorkRecordDocument = gql`
  mutation updateWorkRecord($input: EditWorkRecordInput!) {
    editWorkRecord(input: $input)
  }
`;
export type UpdateWorkRecordMutationFn = Apollo.MutationFunction<
  UpdateWorkRecordMutation,
  UpdateWorkRecordMutationVariables
>;

/**
 * __useUpdateWorkRecordMutation__
 *
 * To run a mutation, you first call `useUpdateWorkRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkRecordMutation, { data, loading, error }] = useUpdateWorkRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkRecordMutation,
    UpdateWorkRecordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkRecordMutation,
    UpdateWorkRecordMutationVariables
  >(UpdateWorkRecordDocument, options);
}
export type UpdateWorkRecordMutationHookResult = ReturnType<
  typeof useUpdateWorkRecordMutation
>;
export type UpdateWorkRecordMutationResult =
  Apollo.MutationResult<UpdateWorkRecordMutation>;
export type UpdateWorkRecordMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkRecordMutation,
  UpdateWorkRecordMutationVariables
>;
export const ApproveWorkRecordDocument = gql`
  mutation approveWorkRecord($id: ID!, $action: String!) {
    punchWorkRecord(id: $id, action: $action) {
      id
      currentState
    }
  }
`;
export type ApproveWorkRecordMutationFn = Apollo.MutationFunction<
  ApproveWorkRecordMutation,
  ApproveWorkRecordMutationVariables
>;

/**
 * __useApproveWorkRecordMutation__
 *
 * To run a mutation, you first call `useApproveWorkRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveWorkRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveWorkRecordMutation, { data, loading, error }] = useApproveWorkRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useApproveWorkRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveWorkRecordMutation,
    ApproveWorkRecordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveWorkRecordMutation,
    ApproveWorkRecordMutationVariables
  >(ApproveWorkRecordDocument, options);
}
export type ApproveWorkRecordMutationHookResult = ReturnType<
  typeof useApproveWorkRecordMutation
>;
export type ApproveWorkRecordMutationResult =
  Apollo.MutationResult<ApproveWorkRecordMutation>;
export type ApproveWorkRecordMutationOptions = Apollo.BaseMutationOptions<
  ApproveWorkRecordMutation,
  ApproveWorkRecordMutationVariables
>;
export const LoginDocument = gql`
  mutation login($orgName: String!, $id: String!, $password: String!) {
    login(orgKey: $orgName, id: $id, password: $password) {
      token
      user {
        id
        username
        displayName
        roles {
          name
        }
        groups {
          name
        }
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const MeForProfileDocument = gql`
  query meForProfile {
    me {
      ...Profile
    }
  }
  ${ProfileFragmentDoc}
`;

/**
 * __useMeForProfileQuery__
 *
 * To run a query within a React component, call `useMeForProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeForProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeForProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeForProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MeForProfileQuery,
    MeForProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeForProfileQuery, MeForProfileQueryVariables>(
    MeForProfileDocument,
    options,
  );
}
export function useMeForProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeForProfileQuery,
    MeForProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeForProfileQuery, MeForProfileQueryVariables>(
    MeForProfileDocument,
    options,
  );
}
export type MeForProfileQueryHookResult = ReturnType<
  typeof useMeForProfileQuery
>;
export type MeForProfileLazyQueryHookResult = ReturnType<
  typeof useMeForProfileLazyQuery
>;
export type MeForProfileQueryResult = Apollo.QueryResult<
  MeForProfileQuery,
  MeForProfileQueryVariables
>;
export const FetchEmployeeByUserIdDocument = gql`
  query fetchEmployeeByUserId($id: ID!) {
    employees(query: { userID: { eq: $id } }, size: 1) {
      collection {
        ...EmployeeItem
      }
    }
  }
  ${EmployeeItemFragmentDoc}
`;

/**
 * __useFetchEmployeeByUserIdQuery__
 *
 * To run a query within a React component, call `useFetchEmployeeByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEmployeeByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEmployeeByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEmployeeByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchEmployeeByUserIdQuery,
    FetchEmployeeByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchEmployeeByUserIdQuery,
    FetchEmployeeByUserIdQueryVariables
  >(FetchEmployeeByUserIdDocument, options);
}
export function useFetchEmployeeByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchEmployeeByUserIdQuery,
    FetchEmployeeByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchEmployeeByUserIdQuery,
    FetchEmployeeByUserIdQueryVariables
  >(FetchEmployeeByUserIdDocument, options);
}
export type FetchEmployeeByUserIdQueryHookResult = ReturnType<
  typeof useFetchEmployeeByUserIdQuery
>;
export type FetchEmployeeByUserIdLazyQueryHookResult = ReturnType<
  typeof useFetchEmployeeByUserIdLazyQuery
>;
export type FetchEmployeeByUserIdQueryResult = Apollo.QueryResult<
  FetchEmployeeByUserIdQuery,
  FetchEmployeeByUserIdQueryVariables
>;
export const FetchEmployeeDocument = gql`
  query fetchEmployee($id: ID!) {
    employee(id: $id) {
      ...EmployeeItem
    }
  }
  ${EmployeeItemFragmentDoc}
`;

/**
 * __useFetchEmployeeQuery__
 *
 * To run a query within a React component, call `useFetchEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEmployeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchEmployeeQuery,
    FetchEmployeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchEmployeeQuery, FetchEmployeeQueryVariables>(
    FetchEmployeeDocument,
    options,
  );
}
export function useFetchEmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchEmployeeQuery,
    FetchEmployeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchEmployeeQuery, FetchEmployeeQueryVariables>(
    FetchEmployeeDocument,
    options,
  );
}
export type FetchEmployeeQueryHookResult = ReturnType<
  typeof useFetchEmployeeQuery
>;
export type FetchEmployeeLazyQueryHookResult = ReturnType<
  typeof useFetchEmployeeLazyQuery
>;
export type FetchEmployeeQueryResult = Apollo.QueryResult<
  FetchEmployeeQuery,
  FetchEmployeeQueryVariables
>;
export const SearchEmployeesDocument = gql`
  query searchEmployees(
    $query: EmployeeQueryInput
    $from: Int
    $size: Int
    $order: [EmployeeOrderInput]
  ) {
    employees(query: $query, from: $from, size: $size, order: $order) {
      collection {
        ...EmployeeItem
      }
      total
    }
  }
  ${EmployeeItemFragmentDoc}
`;

/**
 * __useSearchEmployeesQuery__
 *
 * To run a query within a React component, call `useSearchEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEmployeesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      from: // value for 'from'
 *      size: // value for 'size'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSearchEmployeesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchEmployeesQuery,
    SearchEmployeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchEmployeesQuery, SearchEmployeesQueryVariables>(
    SearchEmployeesDocument,
    options,
  );
}
export function useSearchEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchEmployeesQuery,
    SearchEmployeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchEmployeesQuery,
    SearchEmployeesQueryVariables
  >(SearchEmployeesDocument, options);
}
export type SearchEmployeesQueryHookResult = ReturnType<
  typeof useSearchEmployeesQuery
>;
export type SearchEmployeesLazyQueryHookResult = ReturnType<
  typeof useSearchEmployeesLazyQuery
>;
export type SearchEmployeesQueryResult = Apollo.QueryResult<
  SearchEmployeesQuery,
  SearchEmployeesQueryVariables
>;
export const UpdateEmployeeDocument = gql`
  mutation updateEmployee($id: ID!, $input: EmployeeUpdateInput!) {
    updateEmployee(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables
>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmployeeMutation,
    UpdateEmployeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmployeeMutation,
    UpdateEmployeeMutationVariables
  >(UpdateEmployeeDocument, options);
}
export type UpdateEmployeeMutationHookResult = ReturnType<
  typeof useUpdateEmployeeMutation
>;
export type UpdateEmployeeMutationResult =
  Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($id: ID!, $password: String!) {
    resetPassword(id: $id, password: $password)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const MoveWorkrecordToDeletedWorkrecordDocument = gql`
  mutation moveWorkrecordToDeletedWorkrecord(
    $currentState: String!
    $deleteReason: String
    $deletedDate: Date!
    $id: ID!
  ) {
    moveToDeletedWorkrecord(
      input: {
        currentState: $currentState
        deleteReason: $deleteReason
        deletedDate: $deletedDate
        id: $id
      }
    )
  }
`;
export type MoveWorkrecordToDeletedWorkrecordMutationFn =
  Apollo.MutationFunction<
    MoveWorkrecordToDeletedWorkrecordMutation,
    MoveWorkrecordToDeletedWorkrecordMutationVariables
  >;

/**
 * __useMoveWorkrecordToDeletedWorkrecordMutation__
 *
 * To run a mutation, you first call `useMoveWorkrecordToDeletedWorkrecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveWorkrecordToDeletedWorkrecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveWorkrecordToDeletedWorkrecordMutation, { data, loading, error }] = useMoveWorkrecordToDeletedWorkrecordMutation({
 *   variables: {
 *      currentState: // value for 'currentState'
 *      deleteReason: // value for 'deleteReason'
 *      deletedDate: // value for 'deletedDate'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMoveWorkrecordToDeletedWorkrecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveWorkrecordToDeletedWorkrecordMutation,
    MoveWorkrecordToDeletedWorkrecordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MoveWorkrecordToDeletedWorkrecordMutation,
    MoveWorkrecordToDeletedWorkrecordMutationVariables
  >(MoveWorkrecordToDeletedWorkrecordDocument, options);
}
export type MoveWorkrecordToDeletedWorkrecordMutationHookResult = ReturnType<
  typeof useMoveWorkrecordToDeletedWorkrecordMutation
>;
export type MoveWorkrecordToDeletedWorkrecordMutationResult =
  Apollo.MutationResult<MoveWorkrecordToDeletedWorkrecordMutation>;
export type MoveWorkrecordToDeletedWorkrecordMutationOptions =
  Apollo.BaseMutationOptions<
    MoveWorkrecordToDeletedWorkrecordMutation,
    MoveWorkrecordToDeletedWorkrecordMutationVariables
  >;
export const SettingEmployeeWorkingTimesDocument = gql`
  query settingEmployeeWorkingTimes(
    $query: EmployeeWorkingTimeQueryInput
    $sort: [EmployeeWorkingTimeOrderInput]
    $size: Int
    $from: Int
  ) {
    employeeWorkingTimes(
      query: $query
      order: $sort
      size: $size
      from: $from
    ) {
      collection {
        ...EmployeeWorkingTimeItem
      }
      total
    }
  }
  ${EmployeeWorkingTimeItemFragmentDoc}
`;

/**
 * __useSettingEmployeeWorkingTimesQuery__
 *
 * To run a query within a React component, call `useSettingEmployeeWorkingTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingEmployeeWorkingTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingEmployeeWorkingTimesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      sort: // value for 'sort'
 *      size: // value for 'size'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSettingEmployeeWorkingTimesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SettingEmployeeWorkingTimesQuery,
    SettingEmployeeWorkingTimesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SettingEmployeeWorkingTimesQuery,
    SettingEmployeeWorkingTimesQueryVariables
  >(SettingEmployeeWorkingTimesDocument, options);
}
export function useSettingEmployeeWorkingTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingEmployeeWorkingTimesQuery,
    SettingEmployeeWorkingTimesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SettingEmployeeWorkingTimesQuery,
    SettingEmployeeWorkingTimesQueryVariables
  >(SettingEmployeeWorkingTimesDocument, options);
}
export type SettingEmployeeWorkingTimesQueryHookResult = ReturnType<
  typeof useSettingEmployeeWorkingTimesQuery
>;
export type SettingEmployeeWorkingTimesLazyQueryHookResult = ReturnType<
  typeof useSettingEmployeeWorkingTimesLazyQuery
>;
export type SettingEmployeeWorkingTimesQueryResult = Apollo.QueryResult<
  SettingEmployeeWorkingTimesQuery,
  SettingEmployeeWorkingTimesQueryVariables
>;
export const EditSettingEmployeeWorkingTimeDocument = gql`
  mutation editSettingEmployeeWorkingTime(
    $input: EditEmployeeWorkingTimeInput!
  ) {
    editEmployeeWorkingTime(input: $input)
  }
`;
export type EditSettingEmployeeWorkingTimeMutationFn = Apollo.MutationFunction<
  EditSettingEmployeeWorkingTimeMutation,
  EditSettingEmployeeWorkingTimeMutationVariables
>;

/**
 * __useEditSettingEmployeeWorkingTimeMutation__
 *
 * To run a mutation, you first call `useEditSettingEmployeeWorkingTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSettingEmployeeWorkingTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSettingEmployeeWorkingTimeMutation, { data, loading, error }] = useEditSettingEmployeeWorkingTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSettingEmployeeWorkingTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSettingEmployeeWorkingTimeMutation,
    EditSettingEmployeeWorkingTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditSettingEmployeeWorkingTimeMutation,
    EditSettingEmployeeWorkingTimeMutationVariables
  >(EditSettingEmployeeWorkingTimeDocument, options);
}
export type EditSettingEmployeeWorkingTimeMutationHookResult = ReturnType<
  typeof useEditSettingEmployeeWorkingTimeMutation
>;
export type EditSettingEmployeeWorkingTimeMutationResult =
  Apollo.MutationResult<EditSettingEmployeeWorkingTimeMutation>;
export type EditSettingEmployeeWorkingTimeMutationOptions =
  Apollo.BaseMutationOptions<
    EditSettingEmployeeWorkingTimeMutation,
    EditSettingEmployeeWorkingTimeMutationVariables
  >;
export const AddSettingEmployeeWorkingTimeDocument = gql`
  mutation addSettingEmployeeWorkingTime($input: AddEmployeeWorkingTimeInput!) {
    addEmployeeWorkingTime(input: $input)
  }
`;
export type AddSettingEmployeeWorkingTimeMutationFn = Apollo.MutationFunction<
  AddSettingEmployeeWorkingTimeMutation,
  AddSettingEmployeeWorkingTimeMutationVariables
>;

/**
 * __useAddSettingEmployeeWorkingTimeMutation__
 *
 * To run a mutation, you first call `useAddSettingEmployeeWorkingTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSettingEmployeeWorkingTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSettingEmployeeWorkingTimeMutation, { data, loading, error }] = useAddSettingEmployeeWorkingTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSettingEmployeeWorkingTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSettingEmployeeWorkingTimeMutation,
    AddSettingEmployeeWorkingTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSettingEmployeeWorkingTimeMutation,
    AddSettingEmployeeWorkingTimeMutationVariables
  >(AddSettingEmployeeWorkingTimeDocument, options);
}
export type AddSettingEmployeeWorkingTimeMutationHookResult = ReturnType<
  typeof useAddSettingEmployeeWorkingTimeMutation
>;
export type AddSettingEmployeeWorkingTimeMutationResult =
  Apollo.MutationResult<AddSettingEmployeeWorkingTimeMutation>;
export type AddSettingEmployeeWorkingTimeMutationOptions =
  Apollo.BaseMutationOptions<
    AddSettingEmployeeWorkingTimeMutation,
    AddSettingEmployeeWorkingTimeMutationVariables
  >;
export const DeleteSettingEmployeeWorkingTimeDocument = gql`
  mutation deleteSettingEmployeeWorkingTime($id: ID!) {
    deleteEmployeeWorkingTime(id: $id)
  }
`;
export type DeleteSettingEmployeeWorkingTimeMutationFn =
  Apollo.MutationFunction<
    DeleteSettingEmployeeWorkingTimeMutation,
    DeleteSettingEmployeeWorkingTimeMutationVariables
  >;

/**
 * __useDeleteSettingEmployeeWorkingTimeMutation__
 *
 * To run a mutation, you first call `useDeleteSettingEmployeeWorkingTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSettingEmployeeWorkingTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSettingEmployeeWorkingTimeMutation, { data, loading, error }] = useDeleteSettingEmployeeWorkingTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSettingEmployeeWorkingTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSettingEmployeeWorkingTimeMutation,
    DeleteSettingEmployeeWorkingTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSettingEmployeeWorkingTimeMutation,
    DeleteSettingEmployeeWorkingTimeMutationVariables
  >(DeleteSettingEmployeeWorkingTimeDocument, options);
}
export type DeleteSettingEmployeeWorkingTimeMutationHookResult = ReturnType<
  typeof useDeleteSettingEmployeeWorkingTimeMutation
>;
export type DeleteSettingEmployeeWorkingTimeMutationResult =
  Apollo.MutationResult<DeleteSettingEmployeeWorkingTimeMutation>;
export type DeleteSettingEmployeeWorkingTimeMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSettingEmployeeWorkingTimeMutation,
    DeleteSettingEmployeeWorkingTimeMutationVariables
  >;
export const ContractSummariesDocument = gql`
  query contractSummaries($input: ContractSummariesInput) {
    contractSummaries(input: $input) {
      total
      collection {
        contract {
          id
          name
          workplaceName
          code
          salesOffice {
            name
          }
        }
        workRecordSummaries {
          collection {
            currentState
            count
          }
          holidayCount
        }
      }
    }
  }
`;

/**
 * __useContractSummariesQuery__
 *
 * To run a query within a React component, call `useContractSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractSummariesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContractSummariesQuery,
    ContractSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ContractSummariesQuery,
    ContractSummariesQueryVariables
  >(ContractSummariesDocument, options);
}
export function useContractSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContractSummariesQuery,
    ContractSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ContractSummariesQuery,
    ContractSummariesQueryVariables
  >(ContractSummariesDocument, options);
}
export type ContractSummariesQueryHookResult = ReturnType<
  typeof useContractSummariesQuery
>;
export type ContractSummariesLazyQueryHookResult = ReturnType<
  typeof useContractSummariesLazyQuery
>;
export type ContractSummariesQueryResult = Apollo.QueryResult<
  ContractSummariesQuery,
  ContractSummariesQueryVariables
>;
export const EmployeeSummariesDocument = gql`
  query employeeSummaries($input: EmployeeSummariesInput) {
    employeeSummaries(input: $input) {
      total
      collection {
        employee {
          id
          name
          code
        }
        workRecordSummaries {
          collection {
            currentState
            count
          }
          holidayCount
        }
      }
    }
  }
`;

/**
 * __useEmployeeSummariesQuery__
 *
 * To run a query within a React component, call `useEmployeeSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSummariesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployeeSummariesQuery,
    EmployeeSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployeeSummariesQuery,
    EmployeeSummariesQueryVariables
  >(EmployeeSummariesDocument, options);
}
export function useEmployeeSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeeSummariesQuery,
    EmployeeSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployeeSummariesQuery,
    EmployeeSummariesQueryVariables
  >(EmployeeSummariesDocument, options);
}
export type EmployeeSummariesQueryHookResult = ReturnType<
  typeof useEmployeeSummariesQuery
>;
export type EmployeeSummariesLazyQueryHookResult = ReturnType<
  typeof useEmployeeSummariesLazyQuery
>;
export type EmployeeSummariesQueryResult = Apollo.QueryResult<
  EmployeeSummariesQuery,
  EmployeeSummariesQueryVariables
>;
export const CloseMonthDocument = gql`
  mutation closeMonth($input: CloseMonthInput!) {
    closeMonth(input: $input)
  }
`;
export type CloseMonthMutationFn = Apollo.MutationFunction<
  CloseMonthMutation,
  CloseMonthMutationVariables
>;

/**
 * __useCloseMonthMutation__
 *
 * To run a mutation, you first call `useCloseMonthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseMonthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeMonthMutation, { data, loading, error }] = useCloseMonthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseMonthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseMonthMutation,
    CloseMonthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseMonthMutation, CloseMonthMutationVariables>(
    CloseMonthDocument,
    options,
  );
}
export type CloseMonthMutationHookResult = ReturnType<
  typeof useCloseMonthMutation
>;
export type CloseMonthMutationResult =
  Apollo.MutationResult<CloseMonthMutation>;
export type CloseMonthMutationOptions = Apollo.BaseMutationOptions<
  CloseMonthMutation,
  CloseMonthMutationVariables
>;
export const FetchEmployeeCategoriesDocument = gql`
  query fetchEmployeeCategories {
    employeeCategories {
      collection {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useFetchEmployeeCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchEmployeeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEmployeeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEmployeeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchEmployeeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchEmployeeCategoriesQuery,
    FetchEmployeeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchEmployeeCategoriesQuery,
    FetchEmployeeCategoriesQueryVariables
  >(FetchEmployeeCategoriesDocument, options);
}
export function useFetchEmployeeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchEmployeeCategoriesQuery,
    FetchEmployeeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchEmployeeCategoriesQuery,
    FetchEmployeeCategoriesQueryVariables
  >(FetchEmployeeCategoriesDocument, options);
}
export type FetchEmployeeCategoriesQueryHookResult = ReturnType<
  typeof useFetchEmployeeCategoriesQuery
>;
export type FetchEmployeeCategoriesLazyQueryHookResult = ReturnType<
  typeof useFetchEmployeeCategoriesLazyQuery
>;
export type FetchEmployeeCategoriesQueryResult = Apollo.QueryResult<
  FetchEmployeeCategoriesQuery,
  FetchEmployeeCategoriesQueryVariables
>;
export const HolidayRecordDocument = gql`
  query HolidayRecord {
    recordTypes(query: { name: { eq: "休日" } }) {
      collection {
        id
        name
      }
    }
  }
`;

/**
 * __useHolidayRecordQuery__
 *
 * To run a query within a React component, call `useHolidayRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidayRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidayRecordQuery({
 *   variables: {
 *   },
 * });
 */
export function useHolidayRecordQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HolidayRecordQuery,
    HolidayRecordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HolidayRecordQuery, HolidayRecordQueryVariables>(
    HolidayRecordDocument,
    options,
  );
}
export function useHolidayRecordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HolidayRecordQuery,
    HolidayRecordQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HolidayRecordQuery, HolidayRecordQueryVariables>(
    HolidayRecordDocument,
    options,
  );
}
export type HolidayRecordQueryHookResult = ReturnType<
  typeof useHolidayRecordQuery
>;
export type HolidayRecordLazyQueryHookResult = ReturnType<
  typeof useHolidayRecordLazyQuery
>;
export type HolidayRecordQueryResult = Apollo.QueryResult<
  HolidayRecordQuery,
  HolidayRecordQueryVariables
>;
export const CloseStatusesDocument = gql`
  query CloseStatuses($query: CloseStatusQueryInput) {
    closeStatuses(query: $query) {
      total
      items
      size
      from
      collection {
        status
        id
        contractID
        closeDate
        updatedByID
        executedAt
        executedById
      }
    }
  }
`;

/**
 * __useCloseStatusesQuery__
 *
 * To run a query within a React component, call `useCloseStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloseStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloseStatusesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCloseStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CloseStatusesQuery,
    CloseStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CloseStatusesQuery, CloseStatusesQueryVariables>(
    CloseStatusesDocument,
    options,
  );
}
export function useCloseStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloseStatusesQuery,
    CloseStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CloseStatusesQuery, CloseStatusesQueryVariables>(
    CloseStatusesDocument,
    options,
  );
}
export type CloseStatusesQueryHookResult = ReturnType<
  typeof useCloseStatusesQuery
>;
export type CloseStatusesLazyQueryHookResult = ReturnType<
  typeof useCloseStatusesLazyQuery
>;
export type CloseStatusesQueryResult = Apollo.QueryResult<
  CloseStatusesQuery,
  CloseStatusesQueryVariables
>;
