import {
  Box,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useRouter } from "next/navigation";
import style from "./StaffDrawer.module.scss";
import { TMouseEvent } from "@/types/types";
import { useWorkflowUser } from "@/hooks";
import { buildYearMonth } from "@/lib/utils/date";

type StaffDrawerProps = {
  isOpen: boolean;
  onClose: TMouseEvent;
};

const StaffDrawer = ({ isOpen, onClose }: StaffDrawerProps) => {
  const router = useRouter();
  const { isApprover } = useWorkflowUser();
  const forward = (path: string): TMouseEvent => {
    return (e) => {
      router.push(path);
      onClose(e);
    };
  };
  const yearMonth = buildYearMonth();

  return (
    <Drawer className={style.managerDrawer} open={isOpen} onClose={onClose}>
      <Box className={style.menulistContainer}>
        <List>
          {/* 勤怠報告の承認 */}
          {isApprover && (
            <ListItemButton
              component={Link}
              className="category-item"
              onClick={forward(`/staff/workrecord/approve`)}
            >
              <ListItemText primary="勤怠報告の承認" />
            </ListItemButton>
          )}
          {/* 勤怠報告の申請 */}
          <ListItemButton
            component={Link}
            className="category-item"
            onClick={forward(`/staff/workrecord/new`)}
          >
            <ListItemText primary="勤怠報告の申請" />
          </ListItemButton>
          {/* 過去の勤怠報告を確認 */}
          <ListItemButton
            component={Link}
            className="category-item"
            onClick={forward(`/staff/workrecord/history`)}
          >
            <ListItemText primary="過去の勤怠報告を確認" />
          </ListItemButton>
          {/* 事務担当以上の画面へ */}
          {isApprover && (
            <ListItemButton
              component={Link}
              className="category-item"
              onClick={forward(`/manager/workrecord/${yearMonth}/all`)}
            >
              <ListItemText primary="管理画面へ" />
            </ListItemButton>
          )}
          {/* ヘルプページ */}
          <ListItemButton
            component={Link}
            className="category-item"
            href={"https://sdh-webkintai.zendesk.com/hc/ja"}
            target="_blank"
          >
            <ListItemText primary="ヘルプ(外部サイト)" />
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
};

export default StaffDrawer;
