import { useCallback, useMemo, useState } from "react";

export function useRowBooleanState() {
  //manage  boolean state
  const [BooleanState, setExternalBooleanState] = useState<{
    [rowID: string]: {
      [key: string]: boolean;
    };
  }>({});
  //get  boolean state by row ID
  const rowBooleanState = useMemo(
    () => (rowId: number | string) => BooleanState[rowId],
    [BooleanState]
  );
  //update row state
  const updateRowBooleanState = useCallback(
    (rowId: number | string) => (key: string) => (value: boolean) => {
      setExternalBooleanState((_BooleanState) => {
        if (_BooleanState[rowId]) {
          if (_BooleanState[rowId][key]) {
            return {
              ..._BooleanState,
              [rowId]: { ..._BooleanState[rowId], [key]: value },
            };
          } else {
            return {
              ..._BooleanState,
              [rowId]: { ..._BooleanState[rowId], [key]: value },
            };
          }
        } else {
          return {
            ..._BooleanState,
            [rowId]: { [key]: value },
          };
        }
      });
    },
    [setExternalBooleanState]
  );

  return { rowBooleanState, updateRowBooleanState };
}

//string
export function useRowStringState() {
  //manage string state
  const [stringState, setExternalStringState] = useState<{
    [rowID: string]: {
      [key: string]: string;
    };
  }>({});
  //get  boolean state by row ID
  const rowStringState: (rowId: number | string) => {
    [key: string]: string;
  } = useMemo(() => {
    return (rowId: number | string) => stringState[rowId];
  }, [stringState]);
  //update row state
  const updateRowStringState = useCallback(
    (rowId: number | string) => (key: string) => (value: string) => {
      setExternalStringState((_stringState) => {
        if (_stringState[rowId]) {
          if (_stringState[rowId][key]) {
            return {
              ..._stringState,
              [rowId]: { ..._stringState[rowId], [key]: value },
            };
          } else {
            return {
              ..._stringState,
              [rowId]: { ..._stringState[rowId], [key]: value },
            };
          }
        } else {
          return {
            ..._stringState,
            [rowId]: { [key]: value },
          };
        }
      });
    },
    [setExternalStringState]
  );

  return { rowStringState, updateRowStringState };
}

//number
export function useRowNumberState() {
  //manage  boolean state
  const [numberState, setExternalNumberState] = useState<{
    [rowID: string]: {
      [key: string]: number;
    };
  }>({});
  //get number state by row ID
  const rowNumberState = (rowId: number | string) => numberState[rowId];
  //update row state
  const updateRowNumberState =
    (rowId: number | string) => (key: string) => (value: number) => {
      setExternalNumberState((_numberState) => {
        if (_numberState[rowId]) {
          if (_numberState[rowId][key]) {
            return {
              ..._numberState,
              [rowId]: { ..._numberState[rowId], [key]: value },
            };
          } else {
            return {
              ..._numberState,
              [rowId]: { ..._numberState[rowId], [key]: value },
            };
          }
        } else {
          return {
            ..._numberState,
            [rowId]: { [key]: value },
          };
        }
      });
    };

  return { rowNumberState, updateRowNumberState };
}

//date
export function useRowDateState() {
  //manage Date state
  const [DateState, setExternalDateState] = useState<{
    [rowID: string]: {
      [key: string]: Date;
    };
  }>({});
  //get Date state by row ID
  const rowDateState = (rowId: number | string) => DateState[rowId];
  //update row state
  const updateRowDateState =
    (rowId: number | string) => (key: string) => (value: Date) => {
      setExternalDateState((_DateState) => {
        if (_DateState[rowId]) {
          if (_DateState[rowId][key]) {
            return {
              ..._DateState,
              [rowId]: { ..._DateState[rowId], [key]: value },
            };
          } else {
            return {
              ..._DateState,
              [rowId]: { ..._DateState[rowId], [key]: value },
            };
          }
        } else {
          return {
            ..._DateState,
            [rowId]: { [key]: value },
          };
        }
      });
    };

  return { rowDateState, updateRowDateState };
}
