import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  Popper,
  TextField,
} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import { SyntheticEvent, useCallback, useMemo, useRef, useState } from "react";

export function EditCellStringAutoComplete(props: {
  params: CustomGridRenderEditCellParams<string>;
  width?: number;
  noOptionsText?: string;
  externalStringState: {
    [key: string]: string;
  };
  externalBooleanState: {
    [key: string]: boolean;
  };
  validate?: (row: any, input: any) => string;
  validateAsync?: (row: any, input: any) => Promise<string>;
  updateExternalBooleanState: (key: string) => (value: boolean) => void;
  updateExternalStringState: (key: string) => (value: string) => void;

  autoComplete?: (val: string) => void;
  autoCompleteOptionList?: { [fieldName: string]: any }[];
}) {
  //error
  const ref = useRef<HTMLElement>(null);

  const { id, value, field } = props.params;
  const apiRef = useGridApiContext();

  //autoComplete
  // const [options, setOptions] = useState<{[fieldName: string]: any}[]>([]);
  const optionsLabel = useMemo(
    () => props?.autoCompleteOptionList?.map((o) => o[field]),
    [props?.autoCompleteOptionList]
  );

  //state management of textfield
  const onChangeTextValue = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      if (
        props.autoComplete &&
        "value" in event.target &&
        typeof event.target.value === "string"
      ) {
        props.autoComplete(event.target.value);
      }
    },
    [props.autoComplete]
  );

  //state management of autocomplete
  const [formValue, setFormValue] = useState<string | null>(value ?? null);
  //on selected option from autoComplete options
  const onChangeFormValue = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      value: string | null,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<string> | undefined
    ) => {
      //value: selected label of option: need to convert to id for setting as value of column
      if (props?.autoCompleteOptionList?.length) {
        // optionsオブジェクト
        const selectedObject = props?.autoCompleteOptionList?.find(
          (o) => o[field] === value
        );
        if (selectedObject) {
          setFormValue(selectedObject[field]);
          // selectedObjectの全てのkeyはcolumn fieldに対応しており、keyをforEachで回してsetEditCellValueすれば良い。
          Object.keys(selectedObject).forEach((fieldName) => {
            apiRef.current.setEditCellValue({
              id,
              field: fieldName,
              value: selectedObject[fieldName],
            });
          });
        } else {
          setFormValue("");
          Object.keys(props?.autoCompleteOptionList[0]).forEach((fieldName) => {
            apiRef.current.setEditCellValue({
              id,
              field: fieldName,
              value: "",
            });
          });
        }
      }
    },
    [apiRef, props.autoComplete, props?.autoCompleteOptionList, field]
  );

  //update external state when some error occurred
  const error = useMemo(
    () => props.externalStringState?.["errorMessage_" + field] ?? "",
    [props]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
          width: "100%",
        }}
        ref={ref}
      >
        <Autocomplete
          options={optionsLabel ?? []}
          onChange={onChangeFormValue}
          value={formValue}
          noOptionsText={props.noOptionsText}
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ width: "100%", paddingBottom: 0.6, paddingLeft: 0.5 }}
          componentsProps={{
            paper: {
              sx: {
                width: "100%",
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              hiddenLabel
              onChange={onChangeTextValue}
              variant="standard"
              fullWidth
            />
          )}
        />
      </Box>
      <Popper open={error !== ""} anchorEl={ref.current}>
        <Box
          sx={{
            fontSize: "11px",
            color: "red",
            fontWeight: "bold",
            backgroundColor: "#ffffff",
            width: props.width,
            maxWidth: "90%",
            borderRadius: 1,
            boxShadow: 2,
            margin: "4px auto",
            padding: "2px 4px",
          }}
        >
          {error}
        </Box>
      </Popper>
    </>
  );
}
