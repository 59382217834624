import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    staffHeaderTitle: true;
    bold: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    logout: true;
    menu: true;
    action: true;
    actionSmall: true;
    actionBorder: true;
    small: true;
    code: true;
    smallBorder: true;
    borderRed: true;
    smallBorderRed: true;
  }
  interface ButtonPropsColorOverrides {
    background: true;
    white: true;
  }
}
declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    code: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#00A699",
    },
    secondary: {
      main: "#19857b",
    },
    white: {
      main: "#FFFFFF",
    },
    background: {
      default: "#ffffff",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    allVariants: {
      color: "#3D4454",
    },
    h1: {
      fontSize: 16,
      fontWeight: "bold",
    },
    h2: {
      fontSize: 32,
      fontWeight: "bold",
    },
    h3: {
      fontSize: 20,
      fontWeight: "bold",
    },
    h4: {
      fontSize: 13,
      lineHeight: "48px",
    },
    h5: {
      fontSize: 27,
      fontWeight: "bold",
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "staffHeaderTitle" },
          style: {
            font: "normal normal bold 28px Roboto",
            color: "#3D4454",
            letterSpacing: "0.21px;",
            textAlign: "center",
            lineHeight: 1.2,
            marginTop: "0 !important",
          },
        },
        {
          props: { variant: "bold" },
          style: {
            font: "normal normal bold 28px Roboto",
            color: "#3D4454",
            fontWeight: "bold",
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "code" },
          style: {
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            textAlign: "left",
            lineHeight: "14px",
            height: "24px",
            font: "normal normal medium 13px/14px Roboto",
            letterSpacing: "0.01px",
            color: "#00A699",
            border: "1px solid #cfcfd0",
            textTransform: "uppercase",
            marginLeft: "2px",
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "menu" },
          style: {
            ":hover": {
              backgroundColor: "#00746B",
            },
            background: "#00A699 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            width: "100%",
            height: "80px",
            color: "#FFFFFF",
            font: "normal normal bold 24px/14px Roboto;",
          },
        },
        {
          props: { variant: "logout" },
          style: {
            ":hover": {
              backgroundColor: "rgba(245, 0, 87, 0.04)",
            },
            background: "1px solid #D7373FA3;",
            border: "1px solid #D7373FA3",
            borderRadius: "4px",
            width: "100%",
            color: "#D7373F",
            font: "normal normal bold 18px/14px Roboto",
          },
        },
        {
          props: { variant: "action" },
          style: {
            ":disabled": {
              backgroundColor: "#e0e0e0",
            },
            ":hover": {
              backgroundColor: "#00746B",
            },
            background: "#00A699 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            width: "100%",
            color: "#FFFFFF",
            font: "normal normal bold 18px/14px Roboto",
          },
        },
        {
          props: { variant: "actionSmall" },
          style: {
            ":disabled": {
              backgroundColor: "#e0e0e0",
            },
            ":hover": {
              backgroundColor: "#00746B",
            },
            background: "#00A699 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            width: "100%",
            color: "#FFFFFF",
            height: "22px",
            lineHeight: "1",
            minWidth: "0",
            padding: "0px 8px",
            font: "normal normal medium 13px/14px Roboto",
            fontSize: "12px",
          },
        },
        {
          props: { variant: "actionBorder" },
          style: {
            ":hover": {
              backgroundColor: "rgba(0, 116, 107, 0.04)",
            },
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            width: "100%",
            color: "#3D4454",
            border: "1px solid #e0e0e0",
            font: "normal normal bold 18px/14px Roboto",
          },
        },
        {
          props: { variant: "small" },
          style: {
            ":disabled": {
              backgroundColor: "#e0e0e0",
            },
            ":hover": {
              backgroundColor: "#00746B",
            },
            minWidth: "40px",
            width: "40px",
            height: "22px",
            padding: "0",
            background: "#00A699 0% 0% no-repeat padding-box",
            font: "normal normal bold 12px/10px Roboto",
            fontSize: "12px",
            color: "#FFFFFF",
          },
        },
        {
          props: { variant: "code" },
          style: {
            ":hover": {
              backgroundColor: "rgba(0, 116, 107, 0.04)",
            },
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            textAlign: "left",
            height: "24px",
            lineHeight: "1",
            padding: "0px 8px",
            font: "normal normal medium 13px/14px Roboto",
            letterSpacing: "0.01px",
            color: "#00A699",
            border: "1px solid #e0e0e0",
            textTransform: "uppercase",
            marginRight: "10px",
          },
        },
        {
          props: { variant: "borderRed" },
          style: {
            ":disabled": {
              color: "rgba(211, 47, 47, 0.5)",
            },
            ":hover": {
              backgroundColor: "rgba(211, 47, 47, 0.04)",
            },
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            textAlign: "left",
            lineHeight: "1",
            padding: "0px 8px",
            font: "normal normal medium 13px/14px Roboto",
            color: "#D32F2F",
            border: "1px solid rgba(211, 47, 47, 0.5)",
            textTransform: "uppercase",
          },
        },
        {
          props: { variant: "smallBorder" },
          style: {
            ":hover": {
              backgroundColor: "rgba(0, 116, 107, 0.04)",
            },
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            textAlign: "left",
            height: "22px",
            lineHeight: "1",
            minWidth: "0",
            padding: "0px 8px",
            font: "normal normal medium 13px/14px Roboto",
            fontSize: "12px",
            letterSpacing: "0.01px",
            color: "#3D4454",
            border: "1px solid #e0e0e0",
            textTransform: "uppercase",
          },
        },
        {
          props: { variant: "smallBorderRed" },
          style: {
            ":disabled": {
              color: "rgba(211, 47, 47, 0.5)",
            },
            ":hover": {
              backgroundColor: "rgba(211, 47, 47, 0.04)",
            },
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            textAlign: "left",
            height: "22px",
            lineHeight: "1",
            minWidth: "0",
            padding: "0px 8px",
            font: "normal normal medium 13px/14px Roboto",
            fontSize: "12px",
            letterSpacing: "0.01px",
            color: "#D32F2F",
            border: "1px solid rgba(211, 47, 47, 0.5)",
            textTransform: "uppercase",
          },
        },
      ],
      styleOverrides: {
        root: {
          fontWeight: "bold",
          fontSize: "14px",
          padding: "8px 16px",
          textDecoration: "none",
          boxShadow: "none",
        },
        outlined: {
          color: "#3D4454",
          border: "1px solid #e0e0e0",
          "&.Mui-disabled": {
            borderColor: "rgba(0, 0, 0, 0.26)",
            color: "rgba(0, 0, 0, 0.26)",
          },
        },
        sizeLarge: {
          height: "50px",
        },
        sizeMedium: {
          height: "36px",
        },
        sizeSmall: {
          height: "30px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderColor: "#19857b",
          },
          width: "100%",
          "& .MuiInputBase-readOnly": {
            WebkitTextFillColor: "#3D4454",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          "& .MuiInputBase-readOnly": {
            WebkitTextFillColor: "#3D4454",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          fontSize: "14px",
          color: "#7C7C7C",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: "bold",
          width: "120px",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: "16px",
          padding: "16px",
        },
      },
    },
  },
});

export default theme;
