import { Item } from "./RippleLinkListAccordion";
import { buildYearMonth } from "@/lib/utils/date";

const yearMonth = buildYearMonth();

export const achievementsCommonItem: Item = {
  name: "勤務実績",
  children: [
    {
      name: "申請一覧",
      path: `/manager/workrecord/${yearMonth}/all`,
      isActive: false,
    },
  ],
};

export const allAchievementsItem: Item = {
  ...achievementsCommonItem,
  children: [
    ...achievementsCommonItem.children,
    {
      name: "現場",
      path: `/manager/workrecord/${yearMonth}/contract`,
      isActive: false,
    },
    {
      name: "社員",
      path: `/manager/workrecord/${yearMonth}/staff`,
      isActive: false,
    },
    {
      name: "削除済",
      path: `/manager/workrecord/${yearMonth}/deleted`,
      isActive: false,
    },
    {
      name: "シメキリ",
      path: `/manager/workrecord/${yearMonth}/close`,
      isActive: false,
    },
  ],
};

export const achievementsItemForSiteManagerAndNotClerkOrAreaManager: Item = {
  ...achievementsCommonItem,
  children: [
    ...achievementsCommonItem.children,
    {
      name: "削除済",
      path: `/manager/workrecord/${yearMonth}/deleted`,
      isActive: false,
    },
  ],
};

export const workflowSettingItem: Item = {
  name: "ワークフロー設定",
  children: [
    {
      name: "契約と現場責任者・AM",
      path: "/manager/workflow/sitemanager",
      isActive: false,
    },
    {
      name: "営業所と事務担当・営業所長",
      path: "/manager/workflow/officemanager",
      isActive: false,
    },
  ],
};

export const controlItem: Item = {
  name: "管理",
  children: [
    {
      name: "パスワード初期化",
      path: "/manager/setting/resetpassword",
      isActive: false,
    },
  ],
};

export const masterControlItem: Item = {
  name: "マスタ管理",
  children: [
    {
      name: "契約コード",
      path: "/manager/master/contract",
      isActive: false,
    },
    {
      name: "営業所",
      path: "/manager/master/salesoffice",
      isActive: false,
    },
    { name: "社員", path: "/manager/master/staff", isActive: false },
  ],
};

export const concurrentlyEmployedItem: Item = {
  name: "現場画面へ",
  children: [{ name: "現場画面へ", path: "/staff", isActive: false }],
};

export const allLinkListAccortionItems = [
  allAchievementsItem,
  workflowSettingItem,
  controlItem,
  masterControlItem,
  concurrentlyEmployedItem,
];
