import {
  GridRenderCellParams,
  GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import { ColNumber } from "../Col/ColNumber";
import { EditCellNumber } from "../EditCell/EditCellNumber";
import { CellNumber } from "../Cell/CellNumber";

export function ColNumberDef(colProp: {
  col: ColNumber;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
  updateExternalRowBooleanState: (
    rowId: number | string
  ) => (key: string) => (value: boolean) => void;
}) {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    filterable: colProp.col.filterable,
    sortable: colProp.col.sortable,
    renderCell: (params: GridRenderCellParams) => (
      <CellNumber params={params} unit={colProp.col.unit} />
    ),
    type: "number",
    editable: colProp.col.editable,
    width: colProp.col.width,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <EditCellNumber
        params={params}
        updateExternalBooleanState={colProp.updateExternalRowBooleanState(
          params.id
        )}
        width={colProp.col.width}
        externalStringState={colProp.externalRowStringState(params.id)}
      />
    ),
  };
}
