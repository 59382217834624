import { getGridStringOperators } from "@mui/x-data-grid-pro";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import type { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import clsx from "clsx";
import { CellString } from "../Cell/CellString";
import { ColString } from "../Col/ColString";
import { EditCellString } from "../EditCell/EditCellString";
import { EditCellStringAutoComplete } from "../EditCell/EditCellStringAutoComplete";

export function ColStringDef(colProp: {
  col: ColString;
  externalRowBooleanState: (rowId: number | string) => {
    [key: string]: boolean;
  };
  updateExternalRowBooleanState: (
    rowId: number | string
  ) => (key: string) => (value: boolean) => void;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
  updateExternalRowStringState: (
    rowId: number | string
  ) => (key: string) => (value: string) => void;
}) {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    hide: colProp.col.hide,
    type: "string",
    editable: colProp.col.editable || colProp.col.indirectEditable,
    filterable: colProp.col.filterable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    noOptionsText: colProp.col.noOptionsText,
    filterOperators: getGridStringOperators().filter(
      (o) => o.value === "equals" || o.value === "contains"
    ),
    renderCell: (params: CustomGridRenderCellParams<string>) => (
      <CellString valueFormatter={(val: string) => val} params={params} />
    ),
    renderEditCell: colProp.col.indirectEditable
      ? (params: CustomGridRenderCellParams<string>) => (
          <CellString valueFormatter={(val: string) => val} params={params} />
        )
      : colProp.col.autoComplete
      ? (params: CustomGridRenderEditCellParams<string>) => (
          <EditCellStringAutoComplete
            params={params}
            validate={colProp.col.validate}
            validateAsync={colProp.col.validateAsync}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            updateExternalStringState={colProp.updateExternalRowStringState(
              params.id
            )}
            externalStringState={colProp.externalRowStringState(params.id)}
            autoComplete={colProp.col.autoComplete}
            autoCompleteOptionList={colProp.col.autoCompleteOptionList}
            width={colProp.col.width}
            noOptionsText={colProp.col.noOptionsText}
          />
        )
      : (params: CustomGridRenderEditCellParams<string>) => (
          <EditCellString
            params={params}
            validate={colProp.col.validate}
            validateAsync={colProp.col.validateAsync}
            updateExternalBooleanState={colProp.updateExternalRowBooleanState(
              params.id
            )}
            externalBooleanState={colProp.externalRowBooleanState(params.id)}
            updateExternalStringState={colProp.updateExternalRowStringState(
              params.id
            )}
            width={colProp.col.width}
            externalStringState={colProp.externalRowStringState(params.id)}
          />
        ),
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
  };
}
