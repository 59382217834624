import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";

import { useUser } from "@tailor-platform/client";
import { useRouter } from "next/navigation";
import { currentTableState } from "src/cache";

import style from "./AccountMenu.module.scss";
import { useApplyWorkRecord } from "@/hooks";
import ConfirmDialog from "@/components/ConfirmDialog";

const AccountMenu = () => {
  const router = useRouter();
  const { user, dispatchUser } = useUser();
  const { resetWorkRecord } = useApplyWorkRecord();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleAccountMenu =
    () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (event.type === "keydown") {
        return;
      }
      setAnchorEl(event.currentTarget);
    };
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [forwardPath, setForwardPath] = useState<string>("");

  const handleClose = () => setAnchorEl(null);

  const forward = (path: string) => {
    const isForwadable = !currentTableState().isEditing;

    if (isForwadable) {
      router.push(path);
      handleClose();
    } else {
      setForwardPath(path);
      setShowConfirmDialog(true);
      handleClose();
    }
  };
  const onConfirmPageTransition = async () => {
    setShowConfirmDialog(false);
    currentTableState({ isEditing: false });
    router.push(forwardPath);
  };
  const onCloseDialog = () => setShowConfirmDialog(false);
  const onCancelUpdateBreaktime = () => {
    setShowConfirmDialog(false);
  };

  return (
    <>
      {user ? (
        <>
          <Typography
            onClick={toggleAccountMenu()}
            variant="h4"
            className={`${style.account} text`}
          >
            {user?.displayName}
          </Typography>
          <IconButton onClick={toggleAccountMenu()}>
            <ArrowDropDownIcon />
          </IconButton>
        </>
      ) : null}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            forward("/profile");
          }}
        >
          プロフィールの確認
        </MenuItem>
        <MenuItem
          onClick={() => {
            forward("/password");
          }}
        >
          パスワード変更
        </MenuItem>
        <MenuItem
          onClick={() => {
            forward("/login");
            resetWorkRecord();
            dispatchUser({
              type: "delete",
            });
          }}
        >
          ログアウト
        </MenuItem>
      </Menu>
      <ConfirmDialog
        title="編集中のデータがあります。他のページに移動しますか？"
        confirmButtonText="移動する"
        cancelButtonText="戻る"
        contentText="このまま移動すると編集中のデータは編集前の状態に戻ります"
        open={showConfirmDialog}
        onCloseDialog={onCloseDialog}
        onConfirmDialog={onConfirmPageTransition}
        onCancelDialog={onCancelUpdateBreaktime}
      />
    </>
  );
};

export default AccountMenu;
