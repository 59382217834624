import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridFilterInputDate,
} from "@mui/x-data-grid-pro";
import { format, FormatOptionsWithTZ } from "date-fns-tz";
import { parseISO } from "date-fns";
import clsx from "clsx";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import { CellString } from "../Cell/CellString";
import { ColDate } from "../Col/ColDate";

export function ColDateDef(colProp: {
  col: ColDate;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
}) {
  const equalOperator: GridFilterOperator = {
    label: "...に等しい",
    value: "equal",
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value === filterItem.value;
      };
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: { type: "date" },
  };
  const notEqualOperator: GridFilterOperator = {
    label: "...に等しくない",
    value: "notEqual",
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value !== filterItem.value;
      };
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: { type: "date" },
  };
  const smallerThanOperator: GridFilterOperator = {
    label: "<=",
    value: "smallerThan",
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        if (
          typeof params.value === "number" &&
          typeof filterItem.value === "number"
        ) {
          return params.value <= filterItem.value;
        }
        return false;
      };
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: { type: "date" },
  };
  const greaterThanOperator: GridFilterOperator = {
    label: ">=",
    value: "greaterThan",
    getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        if (
          typeof params.value === "number" &&
          typeof filterItem.value === "number"
        ) {
          return params.value >= filterItem.value;
        }
        return false;
      };
    },
    InputComponent: GridFilterInputDate,
    InputComponentProps: { type: "date" },
  };

  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    type: "string",
    editable: colProp.col.editable,
    filterable: colProp.col.filterable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    renderCell: (params: CustomGridRenderCellParams<string>) => (
      <CellString
        params={params}
        valueFormatter={(value: string) => {
          if (!value) {
            return "";
          }
          return format(parseISO(value), "yyyy-MM-dd", {
            timeZone: colProp?.col?.tz,
          } as FormatOptionsWithTZ);
        }}
      />
    ),
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
    filterOperators: [
      equalOperator,
      notEqualOperator,
      greaterThanOperator,
      smallerThanOperator,
    ],
  };
}
