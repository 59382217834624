import { Box, LinearProgress, Stack } from "@mui/material";
import type {
  GridCallbackDetails,
  GridFilterModel,
  GridRowEditStartParams,
  GridRowSelectionModel,
  GridSortModel,
  GridColumnVisibilityModel,
  GridPinnedColumns,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import {
  DataGridPro,
  GridLogicOperator,
  GridRowEditStopParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  jaJP,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColBoolean, isColBoolean } from "./Col/ColBoolean";
import { ColCheckBox } from "./Col/ColCheckBox";
import { ColCustom, isColCustom } from "./Col/ColCustom";
import { ColDate, isColDate } from "./Col/ColDate";
import { ColEnum, isColEnum } from "./Col/ColEnum";
import { ColHidden, isColHidden } from "./Col/ColHidden";
import { ColIntTime, isColIntTime } from "./Col/ColIntTime";
import { ColNumber, isColNumber } from "./Col/ColNumber";
import { ColString, isColString } from "./Col/ColString";
import { ColTime, isColTime } from "./Col/ColTime";
import { ColBooleanDef } from "./ColDefObject/ColBooleanDef";
import { ColCustomDef } from "./ColDefObject/ColCustomDef";
import { ColDateDef } from "./ColDefObject/ColDateDef";
import { ColEnumDef } from "./ColDefObject/ColEnumDef";
import { ColHiddenDef } from "./ColDefObject/ColHiddenDef";
import { ColIntTimeDef } from "./ColDefObject/ColIntTimeDef";
import { ColNumberDef } from "./ColDefObject/ColNumberDef";
import { ColStringDef } from "./ColDefObject/ColStringDef";
import { ColTimeDef } from "./ColDefObject/ColTimeDef";
import { ErrorLayer } from "./error/ErrorLayer";
import { convertToNestedFilterQuery } from "./lib/filterModel";
import { mergeQueryVariables } from "./lib/mergeQueryVariables";
import { useRowBooleanState, useRowStringState } from "./lib/useRowState";
import { randomString } from "./random";
import { ColWithField } from "./Col/Col";
// import {GroupOrderFieldEnum} from "../../apps/sdh-client/src/types/generated";

export enum OrderDirectionEnum {
  Asc = "Asc",
  Desc = "Desc",
}

const rowsPerPageDefault = 20;

type RefetchProps = {
  from?: number | null;
  size?: number | null;
  query?: any;
  order?: any[] | any | null;
};
type RefetchFn = (props: RefetchProps) => void;
export type DataGridRowDefinition<T extends DataLike = DataLike> = Array<
  | ColString<T>
  | ColBoolean<T>
  | ColCustom<T>
  | ColTime<T>
  | ColIntTime<T>
  | ColHidden<T>
  | ColEnum<T>
  | ColDate<T>
  | ColNumber<T>
  | ColCheckBox<T>
>;
export type MutationProps = Record<
  string,
  string | number | boolean | Date | string[]
>;

export type DataLike = {
  readonly id: string | undefined | null;
} & Record<string, unknown>;
interface Props<T extends DataLike> {
  loading: boolean;
  createRow?: ((input: MutationProps) => Promise<boolean>) | undefined;
  updateRow?: (id: string | number, input: MutationProps) => Promise<boolean>;
  deleteRow?: (id: string | number) => Promise<boolean>;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
  data: readonly T[];
  total: number;
  variables?: RefetchProps;
  filterModel?: any;
  dataGridFilterModel?: GridFilterModel;
  onDataGridFilterModelChange?: (model: GridFilterModel) => void;
  error?: string;
  refetch: RefetchFn;
  header?: (props: DatagridHeaderProps) => JSX.Element;
  colDef: DataGridRowDefinition<T>;
  rowsPerPageOptions?: number[];
  dataGridPage?: number;
  onDataGridPageChange?: (page: number) => void;
  dataGridSortModel?: GridSortModel;
  dataGridColumnVisibilityModel?: GridColumnVisibilityModel;
  onDataGridColumnVisibilityModelChange?: (
    model: GridColumnVisibilityModel
  ) => void;
  dataGridPinnedColumns?: GridPinnedColumns;
  onDataGridPinnedColumnsChange?: (model: GridPinnedColumns) => void;
  isRowSelectable?: (props: GridRowParams<T>) => boolean;
  filterMode?: "client" | "server";
  paginationMode?: "client" | "server";
  defaultLimitRows?: number;
}

type FieldNameColTypeMapType = {
  [key: string]:
    | "string"
    | "custom"
    | "checkBox"
    | "date"
    | "enum"
    | "hidden"
    | "intTime"
    | "number"
    | "time"
    | "boolean";
};

const styles = {
  datagrid: {
    width: "100%",
    height: "640px",
    "& .MuiDataGrid-columnHeader": {
      fontSize: "13px",
      padding: "0 4px",
    },
    "& .MuiDataGrid-columnHeader--alignCenter": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        justifyContent: "left",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "13px",
      padding: "0 4px",
      borderRight: "1px solid #e5e5e5",
    },
    "& .MuiDataGrid-cell--textCenter": {
      justifyContent: "left",
    },
    "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
      padding: 0,
    },
    "& .MuiDataGrid-cell--editing": {
      boxShadow: "none",
      "& .MuiInputBase-input": {
        fontSize: "13px",
      },
      "& .MuiBox-root": {
        width: "100%",
        padding: "0 4px",
      },
    },
    "& .MuiDataGrid-editInputCell": {
      padding: 0,
    },
    "& .MuiAutocomplete-root": {
      padding: 0,
    },
    "& .MuiInputBase-hiddenLabel": {
      padding: 0,
    },
    "& .MuiSelect-outlined": {
      padding: 0,
    },
    "& .MuiDataGrid-toolbarContainer": {
      padding: "0px",
      borderBottom: "1px solid #e0e0e0",
      "& .MuiButton-root": {
        padding: "12px 16px",
      },
    },
  },
};

export function DatagridAsync<T extends DataLike>(props: Props<T>) {
  const apiRef = useGridApiRef();
  const defaultPageSize = props.defaultLimitRows ?? rowsPerPageDefault;

  // manage state
  // TODO: describe that use useState for what
  const { rowBooleanState, updateRowBooleanState } = useRowBooleanState();
  const { rowStringState, updateRowStringState } = useRowStringState();
  // //rowStringState editing_{filedName}
  useEffect(() => {
    props.data.forEach((row) => {
      if (!row) {
        return;
      }
      const id = row.id;
      props.colDef.map((col) => {
        if (isColString(col)) {
          const key = row[col.field];
          if (id && typeof key === "string") {
            updateRowStringState(id)("editing_" + col.field)(key);
            updateRowStringState(id)(col.field)(key);
          }
        }
        //ColString以外のeditingに要対応
      });
    });
  }, [props.data, props.colDef, updateRowStringState]);

  // validation func list
  // TODO: type arrangement
  const validationFuncList = useMemo(() => {
    return props.colDef.map((col) => {
      if (
        isColString(col) ||
        isColBoolean(col) ||
        isColEnum(col) ||
        isColDate(col) ||
        isColIntTime(col)
      ) {
        return { func: col.validate, field: col.field };
      }
    });
  }, [props.colDef]);
  const validationAsyncFuncList = useMemo(() => {
    return props.colDef.map((col) => {
      if (
        isColString(col) ||
        isColBoolean(col) ||
        isColEnum(col) ||
        isColDate(col) ||
        isColIntTime(col)
      ) {
        return { func: col.validate, field: col.field };
      }
    });
  }, [props.colDef]);

  //pagenation
  //TODO: type arrangement
  // const [variables, setVariables] = useState<RefetchProps>(props.variables ?? {...DefaultRefetchProps})
  // useEffect(() => {
  //   console.log("vari", props.variables == variables);
  //   if (props.variables && props.variables != variables) setVariables(props.variables)
  // }, [ setVariables])
  // TODO: variablesと同様にページコンポーネント側で定義する
  const [page, setPage] = useState<number>(props.dataGridPage ?? 0);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  //Sort
  const [sortModel, setSortModel] = useState<GridSortModel>(
      props.dataGridSortModel ?? []
  );

  const onSortModelChange = useCallback(
     (sm: GridSortModel) => {
       setSortModel(sm);
       props.refetch({
         ...props.variables,
         order: sm?.map((o) => ({
           field: o.field,
           direction:
              o.sort === "asc"
                 ? OrderDirectionEnum.Asc
                 : o.sort === "desc"
                    ? OrderDirectionEnum.Desc
                    : undefined,
         })),
       });
     },
     [setSortModel, props.refetch, props.variables, sortModel])

  //server error
  const [serverError, setServerError] = useState<string[]>([]);
  const throwServerError = useCallback((message: any) => {
    if (message) setServerError((e) => e.concat(message));
  }, []);
  useEffect(
    () => throwServerError(props.error),
    [throwServerError, props.error]
  );

  //Filter
  // TODO: variablesと同様にページコンポーネント側で定義する
  const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>(
    props.dataGridFilterModel ?? {
      items: [],
      logicOperator: GridLogicOperator.And,
    }
  );

  //map (ColType => FilterType)
  const ColTypeFilterTypeMap: {
    string: "stringFilter";
    custom: "none";
    checkBox: "none";
    date: "dateFilter";
    enum: "enumFilter";
    hidden: "none";
    intTime: "stringFilter";
    number: "numberFilter";
    time: "stringFilter";
    boolean: "booleanFilter";
  } = useMemo(
    () => ({
      string: "stringFilter",
      custom: "none",
      checkBox: "none",
      date: "dateFilter",
      enum: "enumFilter",
      hidden: "none",
      intTime: "stringFilter",
      number: "numberFilter",
      time: "stringFilter",
      boolean: "booleanFilter",
    }),
    []
  );
  //map (FieldName => ColType)
  const FieldNameColTypeMap: FieldNameColTypeMapType = Object.assign(
    {} as FieldNameColTypeMapType,
    ...props.colDef
      .filter((col): col is ColWithField => "field" in col)
      .map((col) => {
        return { [col.field]: col.colType };
      })
  );
  //Filter Model => TailorDBFilterModelItems
  const convertFilterModel = useCallback(
    (gridFilterModel: GridFilterModel) => {
      const isAnd = gridFilterModel?.logicOperator === GridLogicOperator.And;
      const queryItems = gridFilterModel?.items
        .map((item) => {
          return {
            field: item.field,
            operator: item.operator,
            value: item.value,
            filterType: ColTypeFilterTypeMap[FieldNameColTypeMap[item.field]],
          };
        })
        .map((item) => {
          if (item.filterType === "stringFilter") {
            return {
              ...item,
              operator:
                item.operator === "equals"
                  ? "eq"
                  : item.operator === "isEmpty"
                  ? "exists"
                  : item.operator === "isNotEmpty"
                  ? "exists"
                  : item.operator === "contains"
                  ? "contains"
                  : undefined,
              value:
                item.operator === "isEmpty"
                  ? false
                  : item.operator === "isNotEmpty"
                  ? true
                  : item.operator === "contains"
                  ? item.value
                  : item.value,
            };
          } else if (item.filterType === "dateFilter") {
            return {
              ...item,
              operator:
                item.operator === "equal"
                  ? "eq"
                  : item.operator === "notEqual"
                  ? "ne"
                  : item.operator === "smallerThan"
                  ? "lte"
                  : item.operator === "greaterThan"
                  ? "gte"
                  : item.operator === "isEmpty"
                  ? "exists"
                  : item.operator === "isNotEmpty"
                  ? "exists"
                  : undefined,
              value:
                item.operator === "isEmpty"
                  ? false
                  : item.operator === "isNotEmpty"
                  ? true
                  : item.value,
            };
          } else if (item.filterType === "enumFilter") {
            return {
              ...item,
              operator:
                item.operator === "equal"
                  ? "eq"
                  : item.operator === "notEqual"
                  ? "ne"
                  : item.operator === "isNotEmpty"
                  ? "exists"
                  : undefined,
              value: item.operator === "isNotEmpty" ? true : item.value,
            };
          } else if (item.filterType === "booleanFilter") {
            return {
              ...item,
              operator:
                item.operator === "isEmpty"
                  ? "exists"
                  : item.operator === "isNotEmpty"
                  ? "exists"
                  : item.operator === "isTrue"
                  ? "eq"
                  : item.operator === "isFalse"
                  ? "eq"
                  : undefined,
              value:
                item.operator === "isTrue"
                  ? true
                  : item.operator === "isFalse"
                  ? false
                  : item.operator === "isEmpty"
                  ? false
                  : item.operator === "isNotEmpty"
                  ? true
                  : item.value,
            };
          } else {
            return undefined;
          }
        })
        .filter(
          (item): item is NonNullable<typeof item> =>
            item != null &&
            item.operator != undefined &&
            item.value != undefined &&
            item.field != undefined
        );

      return convertToNestedFilterQuery(queryItems, isAnd);
    },
    [ColTypeFilterTypeMap, FieldNameColTypeMap]
  );

  //create new row
  const [creatingRowID, setCreatingRowID] = useState<string | undefined>();
  const startCreateRow = useCallback(() => {
    const newRowID = "new" + randomString(10);
    setCreatingRowID(newRowID);
    setTimeout(() => {
      apiRef.current.startRowEditMode({ id: newRowID });
      updateRowBooleanState(newRowID)("isEditing")(true);
    }, 300);
  }, [apiRef, setCreatingRowID, updateRowBooleanState]);

  //manage fetching rows
  type Unpacked<T> = T extends readonly (infer U)[] ? U : never;
  const rowsData = useMemo(() => {
    const rowsWithID = creatingRowID
      ? [{ id: creatingRowID as DataLike["id"] }].concat(props.data)
      : props.data;

    return rowsWithID.map((row) => {
      const b = { ...row };
      for (const i in props.colDef) {
        const def = props.colDef[i];
        if (def && def.resolve && def.colType !== "checkbox" && def?.field) {
          const value = def.resolve(row as Unpacked<typeof props.data>);
          type Field = keyof typeof b;
          const field = def.field as Field;
          b[field] = value as Field;
        }
      }
      return b;
    });
  }, [props, creatingRowID]);

  //TODO: getColumnWidth: () => {field: string, width: number}[]
  //TODO: setColumnWidth(width変化をsubscribeして実装): ({field: string, width: number}) => void

  const fieldList = useMemo(
    () =>
      props.colDef
        .filter((col): col is ColWithField => "field" in col)
        .map((col) => {
          return col.field as string;
        }),
    [props.colDef]
  );

  //generate data json to import Datagrid(Mui) from Col components
  const columnsJson = useMemo(
    () =>
      props.colDef
        .map((col) => {
          if (isColString(col)) {
            return ColStringDef({
              col,
              externalRowBooleanState: rowBooleanState,
              updateExternalRowBooleanState: updateRowBooleanState,
              externalRowStringState: rowStringState,
              updateExternalRowStringState: updateRowStringState,
            });
          } else if (isColNumber(col)) {
            return ColNumberDef({
              col,
              externalRowStringState: rowStringState,
              updateExternalRowBooleanState: updateRowBooleanState,
            });
          } else if (isColEnum(col)) {
            return ColEnumDef({
              col,
              externalRowBooleanState: rowBooleanState,
              updateExternalRowBooleanState: updateRowBooleanState,
              externalRowStringState: rowStringState,
            });
          } else if (isColBoolean(col)) {
            return ColBooleanDef({
              col,
              externalRowStringState: rowStringState,
              updateExternalRowBooleanState: updateRowBooleanState,
            });
          } else if (isColDate(col)) {
            return ColDateDef({ col, externalRowStringState: rowStringState });
          } else if (isColTime(col)) {
            return ColTimeDef({ col, externalRowStringState: rowStringState });
          } else if (isColIntTime(col)) {
            return ColIntTimeDef({
              col,
              externalRowStringState: rowStringState,
            });
          } else if (isColCustom(col)) {
            return ColCustomDef({
              col,
              apiRef,
              externalRowBooleanState: rowBooleanState,
              updateExternalRowBooleanState: updateRowBooleanState,
              createRow: props.createRow,
              updateRow: props.updateRow,
              deleteRow: props.deleteRow,
              refetch: () =>
                props?.variables ? props.refetch(props.variables) : {},
              setCreatingRowID,
              creatingRowID,
              throwServerError,
            });
          } else if (isColHidden(col)) {
            return ColHiddenDef({ col });
          } else {
            return undefined;
          }
        })
        .filter(
          (item): item is Exclude<typeof item, undefined> => item !== undefined
        ),
    [
      props,
      rowBooleanState,
      updateRowBooleanState,
      rowStringState,
      updateRowStringState,
      apiRef,
      creatingRowID,
      throwServerError,
    ]
  );

  const columnVisibilityModel = columnsJson.reduce(
    (acc: Record<string, boolean>, item: Record<string, any>) => {
      if (item.hide) {
        acc[item.field] = false;
        delete item.hide;
      }
      return acc;
    },
    {}
  );

  //name of cols pinned to left or right side of Datagrid
  const pinnedLeftColumnsFieldName = useMemo(
    () =>
      props.colDef
        .filter((col: any) => col.field && col && col.pin && col.pin === "left")
        .map((col: any) => col.field),
    [props.colDef]
  );
  const pinnedRightColumnsFieldName = useMemo(
    () =>
      props.colDef
        .filter(
          (col: any) => col.field && col && col.pin && col.pin === "right"
        )
        .map((col: any) => col.field),
    [props.colDef]
  );

  //check existence of ColCheckBox
  const checkboxSelection: boolean = useMemo(
    () => !!props.colDef.find((col) => col.colType === "checkbox"),
    [props.colDef]
  );

  //get info of checked rows
  const getCheckedRows = useCallback(() => {
    // guard emptiness of apiRef while loading
    if (!apiRef.current.getSelectedRows) {
      return [];
    }
    const selectedRowsMap = apiRef.current.getSelectedRows();
    const selectedRowList: { [field: string]: any }[] = [];
    selectedRowsMap.forEach((value) => {
      selectedRowList.push(value);
    });
    return selectedRowList;
  }, [apiRef]);

  const setSubscription = useCallback(() => {
    apiRef.current.subscribeEvent(
      "rowEditStart",
      (paramsEvent: GridRowEditStartParams<any>) => {
        updateRowBooleanState(paramsEvent.id)("isEditing")(true);
      }
    );
    apiRef.current.subscribeEvent(
      "rowEditStop",
      (paramsEvent: GridRowEditStopParams<any>) => {
        apiRef.current.startRowEditMode({ id: paramsEvent.id });
      }
    );
  }, [apiRef, updateRowBooleanState]);

  //detect double click
  useEffect(() => setSubscription(), [setSubscription]);

  // header renderer
  const renderDatagridHeader = () => {
    if (props.header) {
      return (
        <Box>
          {props.header({
            getCheckedRows,
            startCreateRow,
            creatingRowID,
            throwServerError,
            setFilterModel: (model) => {
              props.refetch({
                ...props.variables,
                query: {
                  ...props.variables?.query,
                  ...model,
                },
              });
            },
          })}
        </Box>
      );
    }
  };

  return (
    <Stack spacing={3} sx={{ width: "100%" }}>
      {renderDatagridHeader()}
      <Box>
        <DataGridPro
          sx={styles.datagrid}
          checkboxSelection={checkboxSelection}
          onRowSelectionModelChange={props?.onRowSelectionModelChange}
          isRowSelectable={props.isRowSelectable}
          columns={columnsJson}
          rows={rowsData}
          apiRef={apiRef}
          initialState={{
            columns: {
              columnVisibilityModel,
            },
          }}
          {...(props.dataGridColumnVisibilityModel && {
            columnVisibilityModel:
              props.dataGridColumnVisibilityModel ?? columnVisibilityModel,
          })}
          onColumnVisibilityModelChange={(model) => {
            props.onDataGridColumnVisibilityModelChange?.(model);
          }}
          pinnedColumns={
            props.dataGridPinnedColumns ?? {
              left: pinnedLeftColumnsFieldName,
              right: pinnedRightColumnsFieldName,
            }
          }
          onPinnedColumnsChange={(model) => {
            props.onDataGridPinnedColumnsChange?.(model);
          }}
          onRowModesModelChange={(params) => {
            Object.keys(params).map((rowId) => {
              const row = fieldList.reduce((prev, field) => {
                return {
                  ...prev,
                  [field]:
                    props.data.find((row) => row?.id === rowId)?.[field] ?? "",
                };
              }, {});
              const input = Object.keys(params[rowId]).reduce((prev, field) => {
                return {
                  ...prev,
                  [field]:
                    (params[rowId] as Record<string, any>)[field].value ?? "",
                };
              }, {});

              //need validationAsync
              if (validationAsyncFuncList.length > 0) {
                updateRowBooleanState(rowId)("isLoading")(true);
                //start validation
                const validateFuncPromiseList = fieldList
                  .map((field) => {
                    return {
                      field,
                      func: validationFuncList.find(
                        (vf) => vf?.field === field
                      ),
                      funcAsync: validationAsyncFuncList.find(
                        (vf) => vf?.field === field
                      ),
                    };
                  })
                  .map(
                    async ({ func: vf, funcAsync: vfAsync, field: field }) => {
                      if (vf && vf.func) {
                        const message = vf.func(row, input);
                        updateRowStringState(rowId)("errorMessage_" + field)(
                          message ?? ""
                        );
                        if (message !== "") {
                          return true;
                        } else {
                          if (vfAsync && vfAsync.func) {
                            const message = await vfAsync.func(row, input);
                            updateRowStringState(rowId)(
                              "errorMessage_" + field
                            )(message ?? "");
                            return message !== "";
                          } else {
                            return false;
                          }
                        }
                      } else {
                        return false;
                      }
                    }
                  );
                Promise.all(validateFuncPromiseList)
                  .then((resultArray) => {
                    updateRowBooleanState(rowId)("isLoading")(false);
                    updateRowBooleanState(rowId)("isError")(
                      resultArray.some(Boolean)
                    );
                  })
                  .catch((err) => {
                    throwServerError(err);
                    updateRowBooleanState(rowId)("isLoading")(false);
                  });
              }
            });
          }}
          loading={props.loading}
          onFilterModelChange={(model) => {
            const filterQuery = convertFilterModel(model);
            const mergedQuery = mergeQueryVariables(
              { ...props.variables },
              filterQuery
            );
            props.onDataGridFilterModelChange?.(model);
            setGridFilterModel(model);
            props.refetch(mergedQuery);
          }}
          filterModel={gridFilterModel}
          filterMode={props.filterMode || "server"}
          editMode="row"
          sortModel={sortModel}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={({
            page: newPage,
            pageSize: newPageSize,
          }) => {
            setPage(newPage);
            props.onDataGridPageChange?.(newPage);
            setPageSize(newPageSize);
            props.refetch({
              ...props.variables,
              from: newPage * (props.variables?.size ?? defaultPageSize),
              size: newPageSize,
            });
          }}
          pageSizeOptions={
            props.rowsPerPageOptions || [5, 20, 50, 100, 500, 1000]
          }
          pagination
          paginationMode={props.paginationMode || "server"}
          rowCount={props.total}
          onSortModelChange={onSortModelChange}
          rowHeight={30}
          columnHeaderHeight={42}
          localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          slots={{
            loadingOverlay: LinearProgress,
            toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                  csvOptions={{
                    delimiter: ",",
                    utf8WithBom: true,
                  }}
                />
              </GridToolbarContainer>
            ),
          }}
        />
      </Box>
      <ErrorLayer errors={serverError} />
    </Stack>
  );
}

export type CheckedRows = { [key: string]: unknown }[];
export interface DatagridHeaderProps {
  getCheckedRows: () => CheckedRows;
  startCreateRow: () => void;
  creatingRowID: string | undefined;
  throwServerError: (message?: string) => void;
  setFilterModel: (filterModel: any) => void;
}
