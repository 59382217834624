import { InMemoryCache, makeVar } from "@apollo/client";
import { CurrentTableState, DatagridTableSettings } from "@/types/types";

export const datagridTableSettingsVar: ReturnType<
  typeof makeVar<DatagridTableSettings>
> = makeVar<DatagridTableSettings>(
  typeof window !== "undefined"
    ? JSON.parse(
        localStorage.getItem("datagridTableSettings") || JSON.stringify({}),
      )
    : {},
);
export const currentTableState: ReturnType<typeof makeVar<CurrentTableState>> =
  makeVar<CurrentTableState>({
    isEditing: false,
  });

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        datagridTableSettings: {
          read() {
            return datagridTableSettingsVar();
          },
        },
      },
    },
  },
});
