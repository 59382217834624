export interface Filter {
  field: string;
  operator: string | undefined;
  value: unknown;
  filterType: string;
}
export const convertToNestedFilterQuery = (
  filters: Filter[],
  isAnd: boolean
): object => {
  const operator = isAnd ? "and" : "or";
  return filters
    .slice()
    .reverse()
    .reduce((prev, curr) => {
      return !curr.operator
        ? prev
        : {
            [operator]: {
              [curr.field]: { [curr.operator]: curr.value },
              ...prev,
            },
          };
    }, {});
};
