import React, { ReactElement, useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Typography } from "@mui/material";
import { useUser } from "@tailor-platform/client";
import { usePathname, useRouter } from "next/navigation";
import basePageLayoutStyle from "./BasePageLayout.module.css";
import AccountMenu from "@/components/AccountMenu";
import CommonDrawer from "@/components/CommonDrawer";
import ManagerDrawer from "@/components/ManagerDrawer";
import StaffDrawer from "@/components/StaffDrawer";
import { MenuCategory } from "@/types/types";
import { User } from "@/types/generated";
import { isManager, isSiteManager } from "@/lib/utils/roles";
import { useFetchEmployeeByUserIdQuery } from "@/graphql/queries.v2.generated";

const genMenuFromPath = (
  pathname: string,
): [MenuCategory | null, string | null] => {
  const paths = pathname.split("/");

  switch (true) {
    case /^\/manager\/workrecord\/[0-9]*$/.test(pathname):
      return ["workrecord", "index"];
    case /^\/manager\/workrecord\/\[yearMonth\]\/(all|contract|staff|deleted|close)$/.test(
      pathname,
    ):
      return ["workrecord", paths[paths.length - 1]];
    case /^\/manager\/workflow\/(officemanager|officeadmin|sitemanager)$/.test(
      pathname,
    ):
    case /^\/manager\/workflow\/contract\/(sitemanager|areamanager)$/.test(
      pathname,
    ):
      return ["workflow", paths[paths.length - 1]];
    case /^\/manager\/master\/(contract|salesoffice|staff)$/.test(pathname):
      return ["master", paths[paths.length - 1]];
    case /^\/manager\/setting\/close$/.test(pathname):
      return ["workrecord", "close"];
    case /^\/manager\/setting\/irregularhour$/.test(pathname):
      return ["setting", "irregularhour"];
    case /^\/manager\/setting\/resetpassword$/.test(pathname):
      return ["setting", "resetpassword"];
    default:
      return [null, null];
  }
};

type BasePageLayoutProps = Required<{
  readonly children: ReactElement;
}>;

const isSettingsPath = (pathname: string) => {
  return /^\/profile/.test(pathname) || /^\/reset-password/.test(pathname);
};

const isSiteManagerFromSettingsPage = (user: User, previousPath?: string) => {
  if (isSiteManager(user) && previousPath) {
    return /^\/manager/.test(previousPath);
  } else {
    return false;
  }
};

export const canDisplayManagerMenu = (
  user: User,
  pathname: string,
  previousPath?: string,
) => {
  return (
    /^\/manager/.test(pathname) ||
    (isSettingsPath(pathname) && isManager(user)) ||
    isSiteManagerFromSettingsPage(user, previousPath)
  );
};

export const canDisplayStaffMenu = (user: User, pathname: string) => {
  return (
    /^\/staff/.test(pathname) ||
    (isSettingsPath(pathname) && !isManager(user) && !!user.roles)
  );
};

const BasePageLayout = ({ children }: BasePageLayoutProps) => {
  const pathname = usePathname() || "";
  const [menuCategory, menu] = genMenuFromPath(pathname);
  const [previousPath, setPreviousPath] = useState<string | undefined>();

  const { user, dispatchUser } = useUser();
  const router = useRouter();

  const { data, loading } = useFetchEmployeeByUserIdQuery({
    variables: { id: user?.id },
    defaultOptions: { fetchPolicy: "no-cache" },
  });

  useEffect(() => {
    if (loading) return;

    const isNotEmployee = !data?.employees?.collection[0];

    if ((!user?.id || isNotEmployee) && !/^\/login/.test(pathname)) {
      router.push("/login");
      dispatchUser({ type: "delete" });
    }
  }, [data, dispatchUser, loading, pathname, router, user]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleDrawer =
    (isOpen: boolean) =>
    (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      if (event.type === "keydown") {
        return;
      }
      setIsMenuOpen(isOpen);
    };

  useEffect(() => {
    // If a site manager navigates to the manager page or staff page, save the previous path.
    // This is used to return to the previous page's menu when the site manager is profile page or password page.
    return () => {
      if (
        isSiteManager(user) &&
        (/^\/manager/.test(pathname) || /^\/staff/.test(pathname))
      ) {
        setPreviousPath(pathname);
      }
    };
  }, [pathname, user]);
  return (
    <>
      {user.roles ? (
        <Box
          className={basePageLayoutStyle.headerNavigation}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          {canDisplayManagerMenu(user, pathname, previousPath) ? (
            <ManagerDrawer
              isOpen={isMenuOpen}
              onClose={toggleDrawer(false)}
              menuCategory={menuCategory}
              menu={menu}
            />
          ) : canDisplayStaffMenu(user, pathname) ? (
            <StaffDrawer isOpen={isMenuOpen} onClose={toggleDrawer(false)} />
          ) : (
            <CommonDrawer
              isOpen={isMenuOpen}
              onClose={toggleDrawer(false)}
              menu={menu}
              login={user}
            />
          )}
          <Typography variant="h1">SDH勤怠</Typography>
          <AccountMenu />
        </Box>
      ) : null}
      {children}
    </>
  );
};

export default BasePageLayout;
