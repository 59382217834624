import { Box } from "@mui/material";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";

export function CellString(props: {
  params: CustomGridRenderCellParams<string>;
  valueFormatter?: (val: string) => string;
}) {
  const { value } = props.params;

  const valueFormatter = props.valueFormatter
    ? props.valueFormatter
    : (val: string) => val;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {valueFormatter(value ?? "")}
    </Box>
  );
}
