import { useUser } from "@tailor-platform/client";
import { useMemo } from "react";
import { AppUserRoles } from "@/types/enums";

export const useWorkflowUser = () => {
  const { user } = useUser();

  const isStaff = useMemo<boolean>(
    () => user?.roles?.find((r) => r.name === AppUserRoles.Staff) !== undefined,
    [user],
  );
  const isSiteManager = useMemo<boolean>(
    () =>
      user?.roles?.find((r) => r.name === AppUserRoles.SiteManager) !==
      undefined,
    [user],
  );
  const isClerk = useMemo<boolean>(
    () => user?.roles?.find((r) => r.name === AppUserRoles.Clerk) !== undefined,
    [user],
  );
  const isAreaManager = useMemo<boolean>(
    () =>
      user?.roles?.find((r) => r.name === AppUserRoles.AreaManager) !==
      undefined,
    [user],
  );
  const isManager = useMemo<boolean>(
    () =>
      user?.roles?.find((r) => r.name === AppUserRoles.SalesOfficeManager) !==
      undefined,
    [user],
  );
  const isAdmin = useMemo<boolean>(
    () => user?.roles?.find((r) => r.name === AppUserRoles.Admin) !== undefined,
    [user],
  );
  const isApprover = useMemo<boolean>(
    () => isSiteManager || isClerk || isAreaManager || isManager,
    [isSiteManager, isClerk, isAreaManager, isManager],
  );

  const userRoles = useMemo(() => {
    return user?.roles
      ?.filter((role) => {
        return [
          "Staff",
          "SiteManager",
          "Clerk",
          "AreaManager",
          "SalesOfficeManager",
          "Admin",
        ].includes(role.name);
      })
      ?.map((role) => role.name);
  }, [user?.roles]);

  const currentRole = useMemo(() => {
    if (isAdmin) {
      return AppUserRoles.Admin;
    }
    if (isManager) {
      return AppUserRoles.SalesOfficeManager;
    }
    if (isSiteManager) {
      return AppUserRoles.SiteManager;
    }
    if (isAreaManager) {
      return AppUserRoles.AreaManager;
    }
    if (isClerk) {
      return AppUserRoles.Clerk;
    }

    return AppUserRoles.Staff;
  }, [isAdmin, isAreaManager, isClerk, isManager, isSiteManager]);

  return {
    user,
    isStaff,
    isSiteManager,
    isClerk,
    isAreaManager,
    isManager,
    isAdmin,
    isApprover,
    currentRole,
    userRoles,
  };
};
