import { Box } from "@mui/material";
import type { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";

export function CellIntTime(props: {
  params: CustomGridRenderCellParams<string>;
}) {
  const { value } = props.params;

  const valueFormatter = (val: string) => {
    const valS = val.split(":");
    if (valS.length !== 2) return "-";
    const hh = valS[0].padStart(2, "0");
    const mm = valS[1].padStart(2, "0");
    return `${hh}:${mm}`;
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {valueFormatter(value ?? "")}
    </Box>
  );
}
