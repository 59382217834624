export function randomString(num: Number) {
  var S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  return Array.from(Array(num))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join("");
}

export function randomNumber(num: Number) {
  var S = "0123456789";
  return Array.from(Array(num))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join("");
}

export function getRandomEnumValue<T extends object>(anEnum: T): T[keyof T] {
  const enumValues = Object.keys(anEnum) as Array<keyof T>;
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  const randomEnumKey = enumValues[randomIndex];
  return anEnum[randomEnumKey];
}

export function randomBoolean() {
  return Math.floor(Math.random() * 2) === 1;
}

export function randomFloat(integerDigit: number, decimalPoint: number) {
  const floatString = String(
    Number(randomNumber(integerDigit)) + Math.random()
  );
  return parseFloat(floatString.slice(0, integerDigit + decimalPoint + 1));
}

export function randomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}
