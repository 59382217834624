import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export function CellStringArray(props: {
  params: GridRenderCellParams<string[]>;
  valueFormatter: (val: string[]) => string[];
}) {
  const { value } = props.params;

  const valueFormatter = props.valueFormatter
    ? props.valueFormatter
    : (val: string[]) => val;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {valueFormatter(value ?? []).join(",")}
    </Box>
  );
}
