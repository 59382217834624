import { Box, Link, List, ListItemButton, ListItemText } from "@mui/material";
import { AccordionBase } from "./AccordionBase";
import s from "./RippleLinkListAccordion.module.scss";

export type Props = {
  items: Item[];
  onClickItem: (value: string, e: TMouseEvent) => void;
};

export type Item = {
  name: string;
  children: Child[];
};

export type Child = {
  name: string;
  path: string;
  isActive?: boolean;
};

export type TMouseEvent =
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>;

export const RippleLinkListAccordion: FC<Props> = ({ items, onClickItem }) => {
  const handleClickItem = (path: string, e: TMouseEvent) => {
    onClickItem(path, e);
  };

  return (
    <Box className={s.rippleLinkListAccordion}>
      {items.map(({ name, children }, index) => {
        return (
          <AccordionBase
            key={"RippleLinkListAccordion-AccordionBase-" + index}
            summary={
              <ListItemButton className={s.summaryButton}>
                <ListItemText primary={name} />
              </ListItemButton>
            }
          >
            {children.map((child, index) => {
              return (
                <List
                  key={"RippleLinkListAccordion-List" + index}
                  className={s.list}
                >
                  <ListItemButton
                    component={Link}
                    onClick={(e) =>
                      handleClickItem(child.path, e as unknown as TMouseEvent)
                    }
                    disabled={child.isActive}
                    classes={{ root: s.detailButton }}
                    sx={{ background: child.isActive ? "#ebebeb" : "" }}
                  >
                    <ListItemText primary={child.name} />
                  </ListItemButton>
                </List>
              );
            })}
          </AccordionBase>
        );
      })}
    </Box>
  );
};

export default RippleLinkListAccordion;
