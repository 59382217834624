/** 業務報告承認ステータス */
export const WorkRecordApproveState = {
  /** 現場責任者承認待ち */
  SiteManagerApprove: "siteManagerApprove",
  /** 事務担当者承認待ち */
  ClerkApprove: "clerkApprove",
  /** AM承認待ち */
  AreaManagerApprove: "areaManagerApprove",
  /** 営業所長承認待ち */
  SalesOfficeManagerApprove: "salesOfficeManagerApprove",
  /** 全承認済み */
  Done: "done",
} as const;

export const workRecordApproveState = Object.values(WorkRecordApproveState);
export type WorkRecordApproveState =
  (typeof WorkRecordApproveState)[keyof typeof WorkRecordApproveState];

/** ロール */
export const AppUserRoles = {
  /** システム管理者 */
  Admin: "Admin",
  /** 営業所長 */
  SalesOfficeManager: "SalesOfficeManager",
  /** AM */
  AreaManager: "AreaManager",
  /** 事務員 */
  Clerk: "Clerk",
  /** 現場責任者 */
  SiteManager: "SiteManager",
  /** Staff */
  Staff: "Staff",
} as const;

export const appUserRoles = Object.values(AppUserRoles);
export type AppUserRoles = (typeof AppUserRoles)[keyof typeof AppUserRoles];
export type EnsureRole<T extends AppUserRoles> = Ensure<AppUserRoles, T>;
