import { format, isValid } from "date-fns";
import { ja } from "date-fns/locale/ja";

const pad = (num: number) => (num < 10 ? "0" + num : num);

/**
 * 0から4759(48時間未満)の数値・文字列をHH:mm形式にして返す
 *
 * @param {number | string | undefined} dateNumber
 * @returns {TailorGqlTime | null}
 */
export const toTwoDayLimitedTailorTimeString = (
  dateNumber?: number | string,
): TailorGqlTime | null => {
  try {
    if (dateNumber === void 0 || dateNumber === "") {
      throw dateNumber;
    }

    const paddedDateNumber = String(dateNumber).padStart(4, "0");
    const isValid = /^(?!-)([0-3][0-9]|4[0-7])[0-5][0-9]$/.test(
      paddedDateNumber,
    );

    if (!isValid) {
      throw dateNumber;
    }

    const hh = paddedDateNumber.slice(-4, -2) as Ht;
    const mm = paddedDateNumber.slice(-2) as Mt;

    return `${hh}:${mm}` as TailorGqlTime;
  } catch (e) {
    console.error(
      `無効なフォーマット: ${e}。4桁の半角数字で入力してください(0000~4759)`,
    );

    return null;
  }
};

/**
 * 現在時刻からyyyymm形式の年月を返す
 * ex) 202203
 * @returns {string}
 */
export const buildYearMonth = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ("00" + (date.getMonth() + 1)).slice(-2);

  return year + month;
};

/**
 * 基準日と日時からhhmm形式で時分を返す
 * 例えば基準日が1/1でtargetの日時が1/2 01:00の場合 2500と返す
 * @param base 基準日
 * @param target 日時
 * @param delimiter default = ""
 * @return string
 */

/***
 *  hhmm の時刻を分の長さに直して、0時0分からの経過した分数として考えて他の hhmm との差分取得などに使用する
 * @param hhmmString
 */
export const convertHhmmToMinuteDuration = (hhmmString: string): number => {
  hhmmString = hhmmString?.replace(":", "");

  const paddedHhmmString = ("0000" + hhmmString).slice(-4);
  const hour = parseInt(paddedHhmmString.slice(0, 2));
  const minute = parseInt(paddedHhmmString.slice(2));
  return hour * 60 + minute;
};

export const formatJaTimeDuration = (breakTime?: number) => {
  if (breakTime === undefined) return "";
  return (
    (breakTime >= 60 ? `${Math.floor(breakTime / 60)}時間` : "") +
    `${breakTime % 60}分`
  );
};

export const formatTimeDuration = (breakTime?: number) => {
  if (!breakTime) return "-";
  return (
    (breakTime >= 60 ? `${pad(Math.floor(breakTime / 60))}` : "") +
    `${pad(breakTime % 60)}`
  );
};

/**
 * 日本時間帯のDate型を返す
 * @param {string | Date} date
 * @returns {Date}
 *
 * @todo
 * @modules/utilsのtoDateはタイムゾーンを設定されておらず、データにズレが生じる恐れがある
 * 潜在的なバグになっているため改善されるまでの間、状況に合わせてtoDateの代わりに使用する
 *
 * @link
 * https://github.com/tailor-inc/tailor-packages/issues/55
 */
export const jaDate = (date?: string | Date): Date => {
  const d = ((d) => {
    if (!d) {
      return d === "" ? new Date(d) : new Date();
    }

    return typeof d === "string" ? new Date(d) : d;
  })(date);

  return new Date(d.toLocaleDateString("ja-JP", { timeZone: "Asia/Tokyo" }));
};

/**
 * 日付データを日本標準時間に変換後、指定のフォーマットの文字列に変換する
 * 日付として無効なデータのの場合はnullを返す
 * @param date
 * @param formatString
 * @returns {string | null}
 *
 * @todo
 * @modules/utilsのtoTailorDateは想定されていない形式の文字列を引数に渡すと"1900-01-01"を固定で返却する
 * 潜在的なバグになっているため改善されるまでの間、状況に合わせてtoTailorDateの代わりに使用する
 *
 * @link
 * https://github.com/tailor-inc/tailor-packages/issues/60
 */
export const formatDate = <T extends string = string>(
  date: string | Date,
  formatString: string,
): T | null => {
  const d = jaDate(date);

  return isValid(d) ? (format(d, formatString, { locale: ja }) as T) : null;
};

export const parseIsoDateStringToJaDateString = (
  dateString: string,
  formatString?: string,
) => {
  if (dateString && isValid(new Date(dateString))) {
    return formatDate(dateString, formatString || "yyyy/M/d(E)");
  } else {
    return "-";
  }
};

// TODO: format 形式 ('HH:mm' や 'HH時mm分' など) を受け取れるようにしたい
export const formatHhmm = (hhmmString: string) => {
  const length = hhmmString.length;
  const hourString = hhmmString.slice(0, length - 2);
  const minuteString = hhmmString.slice(length - 2, length);
  return `${hourString}:${minuteString}` as TailorGqlTime;
};
