import {
  ComponentProps,
  ComponentType,
  createElement,
  ElementType,
  ReactElement,
} from "react";

export const combineComponents = <
  T extends ComponentType | ElementType,
  P extends Omit<ComponentProps<T>, "children">,
>(
  Element: JSX.Element,
  ...components: { component: T; props?: P }[]
): ReactElement => {
  return components.reduce(
    (Element: JSX.Element, { component: Component, props }) =>
      createElement(Component, props, Element),
    Element,
  );
};
