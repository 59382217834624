import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useRouter } from "next/navigation";
import { currentTableState } from "src/cache";
import { RippleLinkListAccordion } from "./RippleLinkListAccordion";
import {
  achievementsItemForSiteManagerAndNotClerkOrAreaManager,
  allAchievementsItem,
  allLinkListAccortionItems,
  concurrentlyEmployedItem,
  controlItem,
  masterControlItem,
  workflowSettingItem,
} from "./ManagerDrawer.module.context";
import s from "./ManagerDrawer.module.scss";
import { useWorkflowUser } from "@/hooks";
import ConfirmDialog from "@/components/ConfirmDialog";
import { AppUserRoles } from "@/types/enums";
import { MenuCategory } from "@/types/types";
import { TMouseEvent } from "@/types/types";

type Props = {
  isOpen: boolean;
  onClose: TMouseEvent;
  menuCategory: MenuCategory | null;
  menu: string | null;
};

type MouseClickEvent =
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>;

const ManagerDrawer: FC<Props> = ({
  isOpen,
  onClose,
  menuCategory: currentMenuCategory,
  menu: currentMenu,
}) => {
  const router = useRouter();
  const { currentRole, isClerk, isAreaManager } = useWorkflowUser();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [forwardPath, setForwardPath] = useState<string>("");

  const forward = (path: string): TMouseEvent => {
    const isForwadable = !currentTableState().isEditing;

    if (isForwadable) {
      return (e) => {
        router.push(path);
        onClose(e);
      };
    } else {
      return (e) => {
        setForwardPath(path);
        setShowConfirmDialog(true);
        onClose(e);
      };
    }
  };

  const onConfirmPageTransition = useCallback(() => {
    setShowConfirmDialog(false);

    router.push(forwardPath);
  }, [forwardPath, router]);

  const onCloseDialog = () => setShowConfirmDialog(false);
  const onCancelUpdateBreaktime = () => {
    setShowConfirmDialog(false);
  };

  const items = useMemo(() => {
    const items = (() => {
      switch (currentRole) {
        // 現場責任者
        case AppUserRoles.SiteManager:
          if (isClerk || isAreaManager) {
            return allLinkListAccortionItems;
          } else {
            return [
              achievementsItemForSiteManagerAndNotClerkOrAreaManager,
              concurrentlyEmployedItem,
            ];
          }

        case AppUserRoles.Clerk:
        case AppUserRoles.AreaManager:
        case AppUserRoles.SalesOfficeManager:
          return [
            allAchievementsItem,
            workflowSettingItem,
            controlItem,
            masterControlItem,
          ];

        case AppUserRoles.Admin:
          return allLinkListAccortionItems;

        default:
          return [];
      }
    })();

    return items.map((item) => {
      const children = item.children.map((child) => {
        const pattern = new RegExp(
          `${currentMenuCategory}/?[^/]*?/?${currentMenu}`,
        );

        // 開いているページのurlのパターンがpathにマッチするか確認してリンクの状態を切り替える
        const isActive = pattern.test(child.path);

        return {
          ...child,
          isActive,
        };
      });

      return {
        ...item,
        children,
      };
    });
  }, [currentRole, isAreaManager, isClerk, currentMenuCategory, currentMenu]);

  return (
    <>
      <Drawer className={s.managerDrawer} open={isOpen} onClose={onClose}>
        <Box className={s.menulistContainer}>
          <RippleLinkListAccordion
            items={items}
            onClickItem={(path, e) =>
              forward(path)(e as unknown as MouseClickEvent)
            }
          />

          <List>
            <ListItemButton
              component={Link}
              className="category-item"
              href={"https://sdh-webkintai.zendesk.com/hc/ja"}
              target="_blank"
            >
              <ListItemText primary="ヘルプ(外部サイト)" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>

      <ConfirmDialog
        title="編集中のデータがあります。他のページに移動しますか？"
        confirmButtonText="移動する"
        cancelButtonText="戻る"
        contentText="このまま移動すると編集中のデータは編集前の状態に戻ります"
        open={showConfirmDialog}
        onCloseDialog={onCloseDialog}
        onConfirmDialog={onConfirmPageTransition}
        onCancelDialog={onCancelUpdateBreaktime}
      />
    </>
  );
};

export default ManagerDrawer;
